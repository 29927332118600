/* tslint:disable */
/* eslint-disable */
/**
 * Nirvana IB API
 * Nirvana IB APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AdditionalInsured
 */
export interface AdditionalInsured {
  /**
   *
   * @type {string}
   * @memberof AdditionalInsured
   */
  name: string;
  /**
   *
   * @type {Address}
   * @memberof AdditionalInsured
   */
  address: Address;
}
/**
 *
 * @export
 * @interface AdditionalInsuredChange
 */
export interface AdditionalInsuredChange {
  /**
   *
   * @type {string}
   * @memberof AdditionalInsuredChange
   */
  changeType: AdditionalInsuredChangeChangeTypeEnum;
  /**
   *
   * @type {AdditionalInsured}
   * @memberof AdditionalInsuredChange
   */
  additionalInsured: AdditionalInsured;
  /**
   *
   * @type {AdditionalInsured}
   * @memberof AdditionalInsuredChange
   */
  oldValue?: AdditionalInsured;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalInsuredChange
   */
  hasWoS: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum AdditionalInsuredChangeChangeTypeEnum {
  Added = 'added',
  Deleted = 'deleted',
  Updated = 'updated',
  Unchanged = 'unchanged',
}

/**
 *
 * @export
 * @interface AdditionalInsuredWithWoS
 */
export interface AdditionalInsuredWithWoS {
  /**
   *
   * @type {AdditionalInsured}
   * @memberof AdditionalInsuredWithWoS
   */
  additionalInsured: AdditionalInsured;
  /**
   *
   * @type {boolean}
   * @memberof AdditionalInsuredWithWoS
   */
  hasWoS: boolean;
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  county?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zip: string;
}
/**
 *
 * @export
 * @interface AddressChange
 */
export interface AddressChange {
  /**
   *
   * @type {string}
   * @memberof AddressChange
   */
  changeType: AddressChangeChangeTypeEnum;
  /**
   *
   * @type {Address}
   * @memberof AddressChange
   */
  address: Address;
  /**
   *
   * @type {Address}
   * @memberof AddressChange
   */
  oldValue?: Address;
}

/**
 * @export
 * @enum {string}
 */
export enum AddressChangeChangeTypeEnum {
  Added = 'added',
  Deleted = 'deleted',
  Updated = 'updated',
  Unchanged = 'unchanged',
}

/**
 *
 * @export
 * @interface BundleDuration
 */
export interface BundleDuration {
  /**
   *
   * @type {string}
   * @memberof BundleDuration
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof BundleDuration
   */
  end: string;
}
/**
 *
 * @export
 * @interface Coverage
 */
export interface Coverage {
  /**
   *
   * @type {string}
   * @memberof Coverage
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Coverage
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Coverage
   */
  limits: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Coverage
   */
  deductible: string;
}
/**
 *
 * @export
 * @interface CoverageGroup
 */
export interface CoverageGroup {
  /**
   *
   * @type {Coverage}
   * @memberof CoverageGroup
   */
  coverage?: Coverage;
  /**
   *
   * @type {Array<SubCoverage>}
   * @memberof CoverageGroup
   */
  subCoverages: Array<SubCoverage>;
}
/**
 *
 * @export
 * @interface CoverageGroup1
 */
export interface CoverageGroup1 {
  /**
   *
   * @type {Coverage}
   * @memberof CoverageGroup1
   */
  parent: Coverage;
  /**
   *
   * @type {Array<SubCoverage>}
   * @memberof CoverageGroup1
   */
  children: Array<SubCoverage>;
}
/**
 *
 * @export
 * @interface CreateEndorsementRequestBody
 */
export interface CreateEndorsementRequestBody {
  /**
   *
   * @type {ProgramType}
   * @memberof CreateEndorsementRequestBody
   */
  programType: ProgramType;
}
/**
 *
 * @export
 * @interface CreateEndorsementResponse
 */
export interface CreateEndorsementResponse {
  /**
   *
   * @type {string}
   * @memberof CreateEndorsementResponse
   */
  endorsementRequestID: string;
}
/**
 *
 * @export
 * @interface Driver
 */
export interface Driver {
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  lastName: string;
  /**
   *
   * @type {boolean}
   * @memberof Driver
   */
  isOwner: boolean;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  licenseNumber: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  licenseState: string;
  /**
   *
   * @type {boolean}
   * @memberof Driver
   */
  isOutOfState: boolean;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof Driver
   */
  dateOfHire: string;
  /**
   *
   * @type {number}
   * @memberof Driver
   */
  yearsOfExperience: number;
}
/**
 *
 * @export
 * @interface DriverChange
 */
export interface DriverChange {
  /**
   *
   * @type {string}
   * @memberof DriverChange
   */
  changeType: DriverChangeChangeTypeEnum;
  /**
   *
   * @type {Driver}
   * @memberof DriverChange
   */
  driver: Driver;
  /**
   *
   * @type {Driver}
   * @memberof DriverChange
   */
  oldValue?: Driver;
}

/**
 * @export
 * @enum {string}
 */
export enum DriverChangeChangeTypeEnum {
  Added = 'added',
  Deleted = 'deleted',
  Updated = 'updated',
  Unchanged = 'unchanged',
}

/**
 *
 * @export
 * @interface EffectiveDateEntry
 */
export interface EffectiveDateEntry {
  /**
   * Identifier for the specific change
   * @type {string}
   * @memberof EffectiveDateEntry
   */
  changeID: string;
  /**
   *
   * @type {string}
   * @memberof EffectiveDateEntry
   */
  effectiveDate: string;
  /**
   *
   * @type {string}
   * @memberof EffectiveDateEntry
   */
  expirationDate: string;
}
/**
 *
 * @export
 * @interface EndorsementChange
 */
export interface EndorsementChange {
  /**
   *
   * @type {EndorsementChangeType}
   * @memberof EndorsementChange
   */
  changeType: EndorsementChangeType;
  /**
   *
   * @type {Array<string>}
   * @memberof EndorsementChange
   */
  descriptions: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum EndorsementChangeType {
  Drivers = 'Drivers',
  Equipments = 'Equipments',
  TerminalLocation = 'Terminal Location',
  MailingAddress = 'Mailing Address',
  Invalid = 'Invalid',
}

/**
 *
 * @export
 * @interface EndorsementRequestDetails
 */
export interface EndorsementRequestDetails {
  /**
   *
   * @type {string}
   * @memberof EndorsementRequestDetails
   */
  endorsementRequestID: string;
  /**
   *
   * @type {EndorsementRequestState}
   * @memberof EndorsementRequestDetails
   */
  state: EndorsementRequestState;
  /**
   *
   * @type {string}
   * @memberof EndorsementRequestDetails
   */
  effectiveDate?: string;
  /**
   *
   * @type {string}
   * @memberof EndorsementRequestDetails
   */
  endorsementNumber: string;
  /**
   *
   * @type {number}
   * @memberof EndorsementRequestDetails
   */
  endorsementFee?: number;
  /**
   *
   * @type {Array<EndorsementChange>}
   * @memberof EndorsementRequestDetails
   */
  endorsementDescription: Array<EndorsementChange>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum EndorsementRequestState {
  EndorsementRequestStateInvalid = 'EndorsementRequestStateInvalid',
  EndorsementRequestStateCreated = 'EndorsementRequestStateCreated',
  EndorsementRequestStateUnderUwReview = 'EndorsementRequestStateUnderUWReview',
  EndorsementRequestStateApproved = 'EndorsementRequestStateApproved',
  EndorsementRequestStateBound = 'EndorsementRequestStateBound',
  EndorsementRequestStateDeclined = 'EndorsementRequestStateDeclined',
  EndorsementRequestStateClosed = 'EndorsementRequestStateClosed',
  EndorsementRequestStateOutOfSync = 'EndorsementRequestStateOutOfSync',
}

/**
 *
 * @export
 * @interface GetAllEndorsementRequestsResponse
 */
export interface GetAllEndorsementRequestsResponse {
  /**
   *
   * @type {Array<EndorsementRequestDetails>}
   * @memberof GetAllEndorsementRequestsResponse
   */
  endorsementRequests: Array<EndorsementRequestDetails>;
}
/**
 *
 * @export
 * @interface GetEndorsementRequestChargesResponseTemp
 */
export interface GetEndorsementRequestChargesResponseTemp {
  /**
   *
   * @type {number}
   * @memberof GetEndorsementRequestChargesResponseTemp
   */
  endorsementFees: number;
  /**
   *
   * @type {Array<PerCoverageCharge>}
   * @memberof GetEndorsementRequestChargesResponseTemp
   */
  perCoverageCharge: Array<PerCoverageCharge>;
}
/**
 *
 * @export
 * @interface GetEndorsementRequestCoveragesResponse
 */
export interface GetEndorsementRequestCoveragesResponse {
  /**
   *
   * @type {Array<SubCoverageChangeGroup>}
   * @memberof GetEndorsementRequestCoveragesResponse
   */
  coverages?: Array<SubCoverageChangeGroup>;
}
/**
 *
 * @export
 * @interface GetEndorsementRequestDriversResponse
 */
export interface GetEndorsementRequestDriversResponse {
  /**
   *
   * @type {Array<DriverChange>}
   * @memberof GetEndorsementRequestDriversResponse
   */
  drivers?: Array<DriverChange>;
}
/**
 *
 * @export
 * @interface GetEndorsementRequestEquipmentsResponse
 */
export interface GetEndorsementRequestEquipmentsResponse {
  /**
   *
   * @type {Array<VehicleChange>}
   * @memberof GetEndorsementRequestEquipmentsResponse
   */
  equipments?: Array<VehicleChange>;
}
/**
 *
 * @export
 * @interface GetEndorsementRequestMiscellaneousResponse
 */
export interface GetEndorsementRequestMiscellaneousResponse {
  /**
   *
   * @type {AddressChange}
   * @memberof GetEndorsementRequestMiscellaneousResponse
   */
  mailingAddress: AddressChange;
  /**
   *
   * @type {AddressChange}
   * @memberof GetEndorsementRequestMiscellaneousResponse
   */
  terminalAddress: AddressChange;
  /**
   *
   * @type {Array<AdditionalInsuredChange>}
   * @memberof GetEndorsementRequestMiscellaneousResponse
   */
  additionalInsureds: Array<AdditionalInsuredChange>;
  /**
   *
   * @type {boolean}
   * @memberof GetEndorsementRequestMiscellaneousResponse
   */
  hasBlanketWaiverOfSubrogation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetEndorsementRequestMiscellaneousResponse
   */
  hasBlanketAdditionalInsured: boolean;
}
/**
 *
 * @export
 * @interface GetEndorsementRequestResponse
 */
export interface GetEndorsementRequestResponse {
  /**
   *
   * @type {EndorsementRequestState}
   * @memberof GetEndorsementRequestResponse
   */
  state: EndorsementRequestState;
  /**
   *
   * @type {Array<EffectiveDateEntry>}
   * @memberof GetEndorsementRequestResponse
   */
  effectiveDates: Array<EffectiveDateEntry>;
  /**
   *
   * @type {string}
   * @memberof GetEndorsementRequestResponse
   */
  defaultEffectiveDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetEndorsementRequestResponse
   */
  defaultExpirationDate?: string;
  /**
   *
   * @type {Array<EndorsementChange>}
   * @memberof GetEndorsementRequestResponse
   */
  changes?: Array<EndorsementChange>;
  /**
   *
   * @type {string}
   * @memberof GetEndorsementRequestResponse
   */
  provisionalEndorsementNumber: string;
  /**
   *
   * @type {Insured}
   * @memberof GetEndorsementRequestResponse
   */
  insured: Insured;
  /**
   *
   * @type {UserInfo}
   * @memberof GetEndorsementRequestResponse
   */
  underwriterInfo: UserInfo;
  /**
   *
   * @type {BundleDuration}
   * @memberof GetEndorsementRequestResponse
   */
  bundleDuration: BundleDuration;
}
/**
 *
 * @export
 * @interface GetInsuranceBundleListRecord
 */
export interface GetInsuranceBundleListRecord {
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  bundleID: string;
  /**
   *
   * @type {Policy}
   * @memberof GetInsuranceBundleListRecord
   */
  policy: Policy;
  /**
   *
   * @type {Insured}
   * @memberof GetInsuranceBundleListRecord
   */
  primaryInsured: Insured;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  expirationDate: string;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  status: GetInsuranceBundleListRecordStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleListRecord
   */
  cta: GetInsuranceBundleListRecordCtaEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum GetInsuranceBundleListRecordStatusEnum {
  Active = 'Active',
  Expired = 'Expired',
  UpForRenewal = 'UpForRenewal',
  Renewed = 'Renewed',
  PendingCancellation = 'PendingCancellation',
  Cancelled = 'Cancelled',
  Stale = 'Stale',
}
/**
 * @export
 * @enum {string}
 */
export enum GetInsuranceBundleListRecordCtaEnum {
  ActionNeeded = 'Action Needed',
  NoActionNeeded = 'No Action Needed',
}

/**
 *
 * @export
 * @interface GetInsuranceBundleResponse
 */
export interface GetInsuranceBundleResponse {
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  policyNumber: string;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  companyName: string;
  /**
   *
   * @type {PackageName}
   * @memberof GetInsuranceBundleResponse
   */
  packageType: PackageName;
  /**
   *
   * @type {number}
   * @memberof GetInsuranceBundleResponse
   */
  totalPremium: number;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  effectiveDate: string;
  /**
   *
   * @type {string}
   * @memberof GetInsuranceBundleResponse
   */
  effectiveDateTo: string;
  /**
   *
   * @type {PolicyStatus}
   * @memberof GetInsuranceBundleResponse
   */
  status: PolicyStatus;
  /**
   *
   * @type {Array<Coverage>}
   * @memberof GetInsuranceBundleResponse
   */
  coverages: Array<Coverage>;
  /**
   *
   * @type {Array<CoverageGroup1>}
   * @memberof GetInsuranceBundleResponse
   */
  coverageGroups: Array<CoverageGroup1>;
  /**
   *
   * @type {Array<Vehicle>}
   * @memberof GetInsuranceBundleResponse
   */
  vehicles: Array<Vehicle>;
  /**
   *
   * @type {Array<Driver>}
   * @memberof GetInsuranceBundleResponse
   */
  drivers: Array<Driver>;
  /**
   *
   * @type {Address}
   * @memberof GetInsuranceBundleResponse
   */
  terminalAddress: Address;
  /**
   *
   * @type {Address}
   * @memberof GetInsuranceBundleResponse
   */
  mailingAddress: Address;
  /**
   *
   * @type {ProducerInfo}
   * @memberof GetInsuranceBundleResponse
   */
  producer: ProducerInfo;
  /**
   *
   * @type {UserInfo}
   * @memberof GetInsuranceBundleResponse
   */
  owner?: UserInfo;
  /**
   *
   * @type {UserInfo}
   * @memberof GetInsuranceBundleResponse
   */
  underwriter: UserInfo;
  /**
   *
   * @type {Array<AdditionalInsured>}
   * @memberof GetInsuranceBundleResponse
   */
  additionalInsureds: Array<AdditionalInsured>;
}
/**
 *
 * @export
 * @interface Insured
 */
export interface Insured {
  /**
   *
   * @type {string}
   * @memberof Insured
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Insured
   */
  DOTNumber: number;
}
/**
 *
 * @export
 * @interface LossPayee
 */
export interface LossPayee {
  /**
   *
   * @type {string}
   * @memberof LossPayee
   */
  name: string;
  /**
   *
   * @type {Address}
   * @memberof LossPayee
   */
  address: Address;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PackageName {
  Basic = 'Basic',
  Standard = 'Standard',
  Complete = 'Complete',
}

/**
 *
 * @export
 * @interface PatchEndorsementRequestCoveragesRequestBody
 */
export interface PatchEndorsementRequestCoveragesRequestBody {
  /**
   *
   * @type {Array<CoverageGroup>}
   * @memberof PatchEndorsementRequestCoveragesRequestBody
   */
  coverages: Array<CoverageGroup>;
}
/**
 *
 * @export
 * @interface PatchEndorsementRequestDriversRequestBody
 */
export interface PatchEndorsementRequestDriversRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchEndorsementRequestDriversRequestBody
   */
  effectiveDate?: string;
  /**
   *
   * @type {Array<Driver>}
   * @memberof PatchEndorsementRequestDriversRequestBody
   */
  drivers: Array<Driver>;
}
/**
 *
 * @export
 * @interface PatchEndorsementRequestEquipmentsRequestBody
 */
export interface PatchEndorsementRequestEquipmentsRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchEndorsementRequestEquipmentsRequestBody
   */
  effectiveDate?: string;
  /**
   *
   * @type {Array<Vehicle>}
   * @memberof PatchEndorsementRequestEquipmentsRequestBody
   */
  vehicles: Array<Vehicle>;
}
/**
 *
 * @export
 * @interface PatchEndorsementRequestMiscellaneousRequestBody
 */
export interface PatchEndorsementRequestMiscellaneousRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchEndorsementRequestMiscellaneousRequestBody
   */
  effectiveDate?: string;
  /**
   *
   * @type {Address}
   * @memberof PatchEndorsementRequestMiscellaneousRequestBody
   */
  mailingAddress?: Address;
  /**
   *
   * @type {Address}
   * @memberof PatchEndorsementRequestMiscellaneousRequestBody
   */
  terminalAddress?: Address;
  /**
   *
   * @type {Array<AdditionalInsuredWithWoS>}
   * @memberof PatchEndorsementRequestMiscellaneousRequestBody
   */
  additionalInsureds?: Array<AdditionalInsuredWithWoS>;
}
/**
 *
 * @export
 * @interface PerCoverageCharge
 */
export interface PerCoverageCharge {
  /**
   *
   * @type {string}
   * @memberof PerCoverageCharge
   */
  coverage: string;
  /**
   *
   * @type {number}
   * @memberof PerCoverageCharge
   */
  charge: number;
}
/**
 *
 * @export
 * @interface Policy
 */
export interface Policy {
  /**
   *
   * @type {string}
   * @memberof Policy
   */
  policyNumber: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum PolicyStatus {
  Active = 'Active',
  Expired = 'Expired',
  UpForRenewal = 'Up for Renewal',
  Renewed = 'Renewed',
  PendingCancellation = 'Pending Cancellation',
  Cancelled = 'Cancelled',
  Stale = 'Stale',
}

/**
 *
 * @export
 * @interface ProducerInfo
 */
export interface ProducerInfo {
  /**
   *
   * @type {string}
   * @memberof ProducerInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ProducerInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ProducerInfo
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ProducerInfo
   */
  phoneNumber: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum ProgramType {
  ProgramTypeFleet = 'ProgramTypeFleet',
  ProgramTypeNonFleetCanopiusNrb = 'ProgramTypeNonFleetCanopiusNRB',
  ProgramTypeNonFleetAdmitted = 'ProgramTypeNonFleetAdmitted',
  ProgramTypeInvalid = 'ProgramTypeInvalid',
}

/**
 *
 * @export
 * @interface SubCoverage
 */
export interface SubCoverage {
  /**
   *
   * @type {string}
   * @memberof SubCoverage
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SubCoverage
   */
  description?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SubCoverage
   */
  limits: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SubCoverage
   */
  deductible: string;
}
/**
 *
 * @export
 * @interface SubCoverageChange
 */
export interface SubCoverageChange {
  /**
   *
   * @type {string}
   * @memberof SubCoverageChange
   */
  changeType: SubCoverageChangeChangeTypeEnum;
  /**
   *
   * @type {SubCoverage}
   * @memberof SubCoverageChange
   */
  subCoverage: SubCoverage;
  /**
   *
   * @type {SubCoverage}
   * @memberof SubCoverageChange
   */
  oldValue?: SubCoverage;
}

/**
 * @export
 * @enum {string}
 */
export enum SubCoverageChangeChangeTypeEnum {
  Added = 'added',
  Deleted = 'deleted',
  Updated = 'updated',
  Unchanged = 'unchanged',
}

/**
 *
 * @export
 * @interface SubCoverageChangeGroup
 */
export interface SubCoverageChangeGroup {
  /**
   *
   * @type {Coverage}
   * @memberof SubCoverageChangeGroup
   */
  coverage: Coverage;
  /**
   *
   * @type {Array<SubCoverageChange>}
   * @memberof SubCoverageChangeGroup
   */
  subCoverages: Array<SubCoverageChange>;
}
/**
 *
 * @export
 * @interface UpdateEndorsementRequestRequestBody
 */
export interface UpdateEndorsementRequestRequestBody {
  /**
   *
   * @type {string}
   * @memberof UpdateEndorsementRequestRequestBody
   */
  defaultEffectiveDate: string;
}
/**
 *
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  dateOfBirth?: string;
  /**
   *
   * @type {Address}
   * @memberof UserInfo
   */
  address?: Address;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  phoneNumber?: string;
}
/**
 *
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  make: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  model: string;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  year: number;
  /**
   *
   * @type {VehicleType}
   * @memberof Vehicle
   */
  vehicleType: VehicleType;
  /**
   *
   * @type {VehicleClass}
   * @memberof Vehicle
   */
  vehicleClass: VehicleClass;
  /**
   *
   * @type {WeightClass}
   * @memberof Vehicle
   */
  weightClass: WeightClass;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  statedValue?: number;
  /**
   *
   * @type {LossPayee}
   * @memberof Vehicle
   */
  lossPayee?: LossPayee;
}
/**
 *
 * @export
 * @interface VehicleChange
 */
export interface VehicleChange {
  /**
   *
   * @type {string}
   * @memberof VehicleChange
   */
  changeType: VehicleChangeChangeTypeEnum;
  /**
   *
   * @type {Vehicle}
   * @memberof VehicleChange
   */
  vehicle: Vehicle;
  /**
   *
   * @type {Vehicle}
   * @memberof VehicleChange
   */
  oldValue?: Vehicle;
}

/**
 * @export
 * @enum {string}
 */
export enum VehicleChangeChangeTypeEnum {
  Added = 'added',
  Deleted = 'deleted',
  Updated = 'updated',
  Unchanged = 'unchanged',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum VehicleClass {
  Agricultural = 'Agricultural',
  AutoHauler = 'AutoHauler',
  BulkCommodity = 'BulkCommodity',
  DryFreight = 'DryFreight',
  Dump = 'Dump',
  Flatbed = 'Flatbed',
  FrontLoader = 'FrontLoader',
  Garbage = 'Garbage',
  Livestock = 'Livestock',
  Logging = 'Logging',
  Lowboy = 'Lowboy',
  PickupTruck = 'PickupTruck',
  Pole = 'Pole',
  RagTop = 'RagTop',
  Reefer = 'Reefer',
  Stake = 'Stake',
  Straight = 'Straight',
  Tank = 'Tank',
  Tilt = 'Tilt',
  TruckTractor = 'TruckTractor',
  Utility = 'Utility',
  Unidentified = 'Unidentified',
  Other = 'Other',
}

/**
 *
 * @export
 * @enum {string}
 */

export enum VehicleType {
  Truck = 'Truck',
  Tractor = 'Tractor',
  Trailer = 'Trailer',
  Pickup = 'Pickup',
  SemiTrailer = 'SemiTrailer',
  SpareSemiTrailer = 'SpareSemiTrailer',
  NonOwnedSemiTrailer = 'NonOwnedSemiTrailer',
}

/**
 * Below is the class conversion to pound for you to select the class A = < 3,000 pounds B = 3,001 to 3,500 pounds C = 3,501 to 4,000 pounds D = 4,001 to 4,500 pounds E = 4,501 to 5,000 pounds F = 5,001 to 5,500 pounds G = 5,501 to 6,000 pounds H = 6,001 to 10,000 pounds 3 = 10,001 to 14,000 pounds 4 = 14,001 to 16,000 pounds 5 = 16,001 to 19,500 pounds 6 = 19,501 to 26,000 pounds 7 = 26,001 to 33,000 pounds 8 = \"> 33,000 pounds\"
 * @export
 * @enum {string}
 */

export enum WeightClass {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  _3 = '3',
  _4 = '4',
  _5 = '5',
  _6 = '6',
  _7 = '7',
  _8 = '8',
}

/**
 * EndorsementappIntakeApi - axios parameter creator
 * @export
 */
export const EndorsementappIntakeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Closes an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeEndorsementRequest: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('closeEndorsementRequest', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'closeEndorsementRequest',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/close`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new endorsement request based on the Insurance Bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEndorsementRequest: async (
      bundleID: string,
      createEndorsementRequestBody: CreateEndorsementRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('createEndorsementRequest', 'bundleID', bundleID);
      // verify required parameter 'createEndorsementRequestBody' is not null or undefined
      assertParamExists(
        'createEndorsementRequest',
        'createEndorsementRequestBody',
        createEndorsementRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request`.replace(
          `{${'bundleID'}}`,
          encodeURIComponent(String(bundleID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEndorsementRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all Endorsement Requests for a bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEndorsementRequests: async (
      bundleID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getAllEndorsementRequests', 'bundleID', bundleID);
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/list`.replace(
          `{${'bundleID'}}`,
          encodeURIComponent(String(bundleID)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequest: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getEndorsementRequest', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequest',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch charges details for an endorsement request. This is a temporary API for QA purposes
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestChargesTemp: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestChargesTemp',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestChargesTemp',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/charges-temp`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestCoverages: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getEndorsementRequestCoverages', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestCoverages',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/coverages`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestDrivers: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getEndorsementRequestDrivers', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestDrivers',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/drivers`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestEquipments: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestEquipments',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestEquipments',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/equipments`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Fetch miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestMiscellaneous: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestMiscellaneous',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'getEndorsementRequestMiscellaneous',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/miscellaneous`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit an endorsement request for review
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitEndorsementRequestForReview: async (
      bundleID: string,
      endorsementRequestID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'submitEndorsementRequestForReview',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'submitEndorsementRequestForReview',
        'endorsementRequestID',
        endorsementRequestID,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/submit`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {UpdateEndorsementRequestRequestBody} updateEndorsementRequestRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequest: async (
      bundleID: string,
      endorsementRequestID: string,
      updateEndorsementRequestRequestBody: UpdateEndorsementRequestRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('updateEndorsementRequest', 'bundleID', bundleID);
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequest',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'updateEndorsementRequestRequestBody' is not null or undefined
      assertParamExists(
        'updateEndorsementRequest',
        'updateEndorsementRequestRequestBody',
        updateEndorsementRequestRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateEndorsementRequestRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestCoveragesRequestBody} patchEndorsementRequestCoveragesRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestCoverages: async (
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestCoveragesRequestBody: PatchEndorsementRequestCoveragesRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestCoverages',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestCoverages',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'patchEndorsementRequestCoveragesRequestBody' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestCoverages',
        'patchEndorsementRequestCoveragesRequestBody',
        patchEndorsementRequestCoveragesRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/coverages`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchEndorsementRequestCoveragesRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestDriversRequestBody} patchEndorsementRequestDriversRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestDrivers: async (
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestDriversRequestBody: PatchEndorsementRequestDriversRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestDrivers',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestDrivers',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'patchEndorsementRequestDriversRequestBody' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestDrivers',
        'patchEndorsementRequestDriversRequestBody',
        patchEndorsementRequestDriversRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/drivers`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchEndorsementRequestDriversRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestEquipmentsRequestBody} patchEndorsementRequestEquipmentsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestEquipments: async (
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestEquipmentsRequestBody: PatchEndorsementRequestEquipmentsRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestEquipments',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestEquipments',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'patchEndorsementRequestEquipmentsRequestBody' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestEquipments',
        'patchEndorsementRequestEquipmentsRequestBody',
        patchEndorsementRequestEquipmentsRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/equipments`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchEndorsementRequestEquipmentsRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestMiscellaneousRequestBody} patchEndorsementRequestMiscellaneousRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestMiscellaneous: async (
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestMiscellaneousRequestBody: PatchEndorsementRequestMiscellaneousRequestBody,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestMiscellaneous',
        'bundleID',
        bundleID,
      );
      // verify required parameter 'endorsementRequestID' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestMiscellaneous',
        'endorsementRequestID',
        endorsementRequestID,
      );
      // verify required parameter 'patchEndorsementRequestMiscellaneousRequestBody' is not null or undefined
      assertParamExists(
        'updateEndorsementRequestMiscellaneous',
        'patchEndorsementRequestMiscellaneousRequestBody',
        patchEndorsementRequestMiscellaneousRequestBody,
      );
      const localVarPath =
        `/insurance-bundle/{bundleID}/endorsement-request/{endorsementRequestID}/miscellaneous`
          .replace(`{${'bundleID'}}`, encodeURIComponent(String(bundleID)))
          .replace(
            `{${'endorsementRequestID'}}`,
            encodeURIComponent(String(endorsementRequestID)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchEndorsementRequestMiscellaneousRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EndorsementappIntakeApi - functional programming interface
 * @export
 */
export const EndorsementappIntakeApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    EndorsementappIntakeApiAxiosParamCreator(configuration);
  return {
    /**
     * Closes an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeEndorsementRequest(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closeEndorsementRequest(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a new endorsement request based on the Insurance Bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEndorsementRequest(
      bundleID: string,
      createEndorsementRequestBody: CreateEndorsementRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateEndorsementResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createEndorsementRequest(
          bundleID,
          createEndorsementRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get all Endorsement Requests for a bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllEndorsementRequests(
      bundleID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetAllEndorsementRequestsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllEndorsementRequests(
          bundleID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequest(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEndorsementRequestResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequest(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch charges details for an endorsement request. This is a temporary API for QA purposes
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestChargesTemp(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEndorsementRequestChargesResponseTemp>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestChargesTemp(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEndorsementRequestCoveragesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestCoverages(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEndorsementRequestDriversResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestDrivers(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEndorsementRequestEquipmentsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Fetch miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetEndorsementRequestMiscellaneousResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Submit an endorsement request for review
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitEndorsementRequestForReview(
      bundleID: string,
      endorsementRequestID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.submitEndorsementRequestForReview(
          bundleID,
          endorsementRequestID,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {UpdateEndorsementRequestRequestBody} updateEndorsementRequestRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequest(
      bundleID: string,
      endorsementRequestID: string,
      updateEndorsementRequestRequestBody: UpdateEndorsementRequestRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequest(
          bundleID,
          endorsementRequestID,
          updateEndorsementRequestRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestCoveragesRequestBody} patchEndorsementRequestCoveragesRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestCoveragesRequestBody: PatchEndorsementRequestCoveragesRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestCoverages(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestCoveragesRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestDriversRequestBody} patchEndorsementRequestDriversRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestDriversRequestBody: PatchEndorsementRequestDriversRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestDrivers(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestDriversRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestEquipmentsRequestBody} patchEndorsementRequestEquipmentsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestEquipmentsRequestBody: PatchEndorsementRequestEquipmentsRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestEquipmentsRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestMiscellaneousRequestBody} patchEndorsementRequestMiscellaneousRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestMiscellaneousRequestBody: PatchEndorsementRequestMiscellaneousRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestMiscellaneousRequestBody,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EndorsementappIntakeApi - factory interface
 * @export
 */
export const EndorsementappIntakeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EndorsementappIntakeApiFp(configuration);
  return {
    /**
     * Closes an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeEndorsementRequest(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .closeEndorsementRequest(bundleID, endorsementRequestID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new endorsement request based on the Insurance Bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEndorsementRequest(
      bundleID: string,
      createEndorsementRequestBody: CreateEndorsementRequestBody,
      options?: any,
    ): AxiosPromise<CreateEndorsementResponse> {
      return localVarFp
        .createEndorsementRequest(
          bundleID,
          createEndorsementRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all Endorsement Requests for a bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllEndorsementRequests(
      bundleID: string,
      options?: any,
    ): AxiosPromise<GetAllEndorsementRequestsResponse> {
      return localVarFp
        .getAllEndorsementRequests(bundleID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequest(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<GetEndorsementRequestResponse> {
      return localVarFp
        .getEndorsementRequest(bundleID, endorsementRequestID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch charges details for an endorsement request. This is a temporary API for QA purposes
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestChargesTemp(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<GetEndorsementRequestChargesResponseTemp> {
      return localVarFp
        .getEndorsementRequestChargesTemp(
          bundleID,
          endorsementRequestID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<GetEndorsementRequestCoveragesResponse> {
      return localVarFp
        .getEndorsementRequestCoverages(bundleID, endorsementRequestID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<GetEndorsementRequestDriversResponse> {
      return localVarFp
        .getEndorsementRequestDrivers(bundleID, endorsementRequestID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<GetEndorsementRequestEquipmentsResponse> {
      return localVarFp
        .getEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Fetch miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<GetEndorsementRequestMiscellaneousResponse> {
      return localVarFp
        .getEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit an endorsement request for review
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitEndorsementRequestForReview(
      bundleID: string,
      endorsementRequestID: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .submitEndorsementRequestForReview(
          bundleID,
          endorsementRequestID,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {UpdateEndorsementRequestRequestBody} updateEndorsementRequestRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequest(
      bundleID: string,
      endorsementRequestID: string,
      updateEndorsementRequestRequestBody: UpdateEndorsementRequestRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateEndorsementRequest(
          bundleID,
          endorsementRequestID,
          updateEndorsementRequestRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update coverage details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestCoveragesRequestBody} patchEndorsementRequestCoveragesRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestCoverages(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestCoveragesRequestBody: PatchEndorsementRequestCoveragesRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateEndorsementRequestCoverages(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestCoveragesRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update driver details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestDriversRequestBody} patchEndorsementRequestDriversRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestDrivers(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestDriversRequestBody: PatchEndorsementRequestDriversRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateEndorsementRequestDrivers(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestDriversRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update equipment details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestEquipmentsRequestBody} patchEndorsementRequestEquipmentsRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestEquipments(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestEquipmentsRequestBody: PatchEndorsementRequestEquipmentsRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateEndorsementRequestEquipments(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestEquipmentsRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update miscellaneous details for an endorsement request
     * @param {string} bundleID The ID of the insurance bundle
     * @param {string} endorsementRequestID The ID of the endorsement request
     * @param {PatchEndorsementRequestMiscellaneousRequestBody} patchEndorsementRequestMiscellaneousRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEndorsementRequestMiscellaneous(
      bundleID: string,
      endorsementRequestID: string,
      patchEndorsementRequestMiscellaneousRequestBody: PatchEndorsementRequestMiscellaneousRequestBody,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateEndorsementRequestMiscellaneous(
          bundleID,
          endorsementRequestID,
          patchEndorsementRequestMiscellaneousRequestBody,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EndorsementappIntakeApi - object-oriented interface
 * @export
 * @class EndorsementappIntakeApi
 * @extends {BaseAPI}
 */
export class EndorsementappIntakeApi extends BaseAPI {
  /**
   * Closes an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public closeEndorsementRequest(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .closeEndorsementRequest(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new endorsement request based on the Insurance Bundle
   * @param {string} bundleID The ID of the insurance bundle
   * @param {CreateEndorsementRequestBody} createEndorsementRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public createEndorsementRequest(
    bundleID: string,
    createEndorsementRequestBody: CreateEndorsementRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .createEndorsementRequest(bundleID, createEndorsementRequestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all Endorsement Requests for a bundle
   * @param {string} bundleID The ID of the insurance bundle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getAllEndorsementRequests(
    bundleID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getAllEndorsementRequests(bundleID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequest(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequest(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch charges details for an endorsement request. This is a temporary API for QA purposes
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestChargesTemp(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestChargesTemp(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch coverage details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestCoverages(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestCoverages(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch driver details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestDrivers(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestDrivers(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch equipment details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestEquipments(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestEquipments(bundleID, endorsementRequestID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fetch miscellaneous details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public getEndorsementRequestMiscellaneous(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .getEndorsementRequestMiscellaneous(
        bundleID,
        endorsementRequestID,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit an endorsement request for review
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public submitEndorsementRequestForReview(
    bundleID: string,
    endorsementRequestID: string,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .submitEndorsementRequestForReview(
        bundleID,
        endorsementRequestID,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {UpdateEndorsementRequestRequestBody} updateEndorsementRequestRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequest(
    bundleID: string,
    endorsementRequestID: string,
    updateEndorsementRequestRequestBody: UpdateEndorsementRequestRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequest(
        bundleID,
        endorsementRequestID,
        updateEndorsementRequestRequestBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update coverage details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {PatchEndorsementRequestCoveragesRequestBody} patchEndorsementRequestCoveragesRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestCoverages(
    bundleID: string,
    endorsementRequestID: string,
    patchEndorsementRequestCoveragesRequestBody: PatchEndorsementRequestCoveragesRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestCoverages(
        bundleID,
        endorsementRequestID,
        patchEndorsementRequestCoveragesRequestBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update driver details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {PatchEndorsementRequestDriversRequestBody} patchEndorsementRequestDriversRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestDrivers(
    bundleID: string,
    endorsementRequestID: string,
    patchEndorsementRequestDriversRequestBody: PatchEndorsementRequestDriversRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestDrivers(
        bundleID,
        endorsementRequestID,
        patchEndorsementRequestDriversRequestBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update equipment details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {PatchEndorsementRequestEquipmentsRequestBody} patchEndorsementRequestEquipmentsRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestEquipments(
    bundleID: string,
    endorsementRequestID: string,
    patchEndorsementRequestEquipmentsRequestBody: PatchEndorsementRequestEquipmentsRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestEquipments(
        bundleID,
        endorsementRequestID,
        patchEndorsementRequestEquipmentsRequestBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update miscellaneous details for an endorsement request
   * @param {string} bundleID The ID of the insurance bundle
   * @param {string} endorsementRequestID The ID of the endorsement request
   * @param {PatchEndorsementRequestMiscellaneousRequestBody} patchEndorsementRequestMiscellaneousRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndorsementappIntakeApi
   */
  public updateEndorsementRequestMiscellaneous(
    bundleID: string,
    endorsementRequestID: string,
    patchEndorsementRequestMiscellaneousRequestBody: PatchEndorsementRequestMiscellaneousRequestBody,
    options?: AxiosRequestConfig,
  ) {
    return EndorsementappIntakeApiFp(this.configuration)
      .updateEndorsementRequestMiscellaneous(
        bundleID,
        endorsementRequestID,
        patchEndorsementRequestMiscellaneousRequestBody,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InsuranceBundleApi - axios parameter creator
 * @export
 */
export const InsuranceBundleApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Retrieves detailed information for a specific insurance bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundle: async (
      bundleID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleID' is not null or undefined
      assertParamExists('getInsuranceBundle', 'bundleID', bundleID);
      const localVarPath = `/insurance-bundle/{bundleID}`.replace(
        `{${'bundleID'}}`,
        encodeURIComponent(String(bundleID)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves a list of all available insurance bundles
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundleList: async (
      programType: ProgramType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'programType' is not null or undefined
      assertParamExists('getInsuranceBundleList', 'programType', programType);
      const localVarPath = `/insurance-bundle/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication sessionIdAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'JSESSIONID',
        configuration,
      );

      if (programType !== undefined) {
        localVarQueryParameter['programType'] = programType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InsuranceBundleApi - functional programming interface
 * @export
 */
export const InsuranceBundleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InsuranceBundleApiAxiosParamCreator(configuration);
  return {
    /**
     * Retrieves detailed information for a specific insurance bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceBundle(
      bundleID: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetInsuranceBundleResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceBundle(bundleID, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Retrieves a list of all available insurance bundles
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInsuranceBundleList(
      programType: ProgramType,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetInsuranceBundleListRecord>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInsuranceBundleList(
          programType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * InsuranceBundleApi - factory interface
 * @export
 */
export const InsuranceBundleApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InsuranceBundleApiFp(configuration);
  return {
    /**
     * Retrieves detailed information for a specific insurance bundle
     * @param {string} bundleID The ID of the insurance bundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundle(
      bundleID: string,
      options?: any,
    ): AxiosPromise<GetInsuranceBundleResponse> {
      return localVarFp
        .getInsuranceBundle(bundleID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves a list of all available insurance bundles
     * @param {ProgramType} programType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInsuranceBundleList(
      programType: ProgramType,
      options?: any,
    ): AxiosPromise<Array<GetInsuranceBundleListRecord>> {
      return localVarFp
        .getInsuranceBundleList(programType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InsuranceBundleApi - object-oriented interface
 * @export
 * @class InsuranceBundleApi
 * @extends {BaseAPI}
 */
export class InsuranceBundleApi extends BaseAPI {
  /**
   * Retrieves detailed information for a specific insurance bundle
   * @param {string} bundleID The ID of the insurance bundle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceBundleApi
   */
  public getInsuranceBundle(bundleID: string, options?: AxiosRequestConfig) {
    return InsuranceBundleApiFp(this.configuration)
      .getInsuranceBundle(bundleID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves a list of all available insurance bundles
   * @param {ProgramType} programType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InsuranceBundleApi
   */
  public getInsuranceBundleList(
    programType: ProgramType,
    options?: AxiosRequestConfig,
  ) {
    return InsuranceBundleApiFp(this.configuration)
      .getInsuranceBundleList(programType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
