import { createContext } from 'react';
import {
  FormState,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { FileRejection } from 'react-dropzone';
import { z } from 'zod';

import { USState } from '@nirvana/api/claims';

export const DESCRIPTION_MAX_LENGTH = 5_000;

const AttachmentSchema = z.object({
  file: z.instanceof(File),
  uploadUrl: z.string().url().optional(),
  downloadUrl: z.string().url().optional(),
  key: z.string().optional(),
  uploadStatus: z.enum(['pending', 'failed', 'success']),
});

export type Attachment = z.infer<typeof AttachmentSchema>;

const ReporterInformation = z.object({
  firstName: z.string().nonempty().default(''),
  lastName: z.string().nonempty().default(''),
  phone: z
    .union([
      z.string().regex(/^\(?\d{3}\)? ?\d{3}-?\d{4}$/), // Valid phone number
      z.string().length(0), // Empty string
    ])
    .optional(),
  email: z.string().email(),
});

export enum PolicyCoverageEnums {
  /** CoverageAutoLiability */
  CoverageAutoLiability = 'CoverageAutoLiability',
  /** CoverageAutoPhysicalDamage */
  CoverageAutoPhysicalDamage = 'CoverageAutoPhysicalDamage',
  /** CoverageGeneralLiability */
  CoverageGeneralLiability = 'CoverageGeneralLiability',
  /** CoverageMotorTruckCargo */
  CoverageMotorTruckCargo = 'CoverageMotorTruckCargo',
}

export const ClaimSchema = z
  .object({
    reporter: ReporterInformation,
    insuredName: z.string().nonempty().default(''),
    insuredDOT: z.string().default(''),
    noticeType: z
      .enum(['initiateClaim', 'onlyReporting'])
      .default('initiateClaim'),
    lossDate: z.date().default(new Date()),
    lossState: z.nativeEnum(USState),
    lossLocation: z.string().default(''),
    policyNumber: z.string().nonempty().default(''),
    lineOfBusiness: z.custom<PolicyCoverageEnums>(),
    ownVehiclesInvolved: z.string().default('yes'),
    insuredVehicleVins: z.string().array().default(['']),
    otherVehiclesInvolved: z.string().default('yes'),
    otherVehicleVins: z.string().array().default(['']),
    police: z.object({
      onTheScene: z.string().default('no'),
      agencyName: z.string().default(''),
      reportNumber: z.string().default(''),
    }),
    injureds: z.string().default('yes'),
    description: z.string().max(DESCRIPTION_MAX_LENGTH).default(''),
    attachments: z.array(AttachmentSchema).default([]),
  })
  .required({
    lossDate: true,
    lossState: true,
    policyNumber: true,
  });

export type ClaimType = z.infer<typeof ClaimSchema>;

type PresignedUploadLink = {
  key: string;
  url: string;
};

type Policies = {
  id: string;
  policyNumber: string;
  coverages: PolicyCoverageEnums[];
}[];

export const NewClaimContext = createContext<{
  formState: FormState<ClaimType>;
  getValues: UseFormGetValues<ClaimType>;
  viewAllClaimsLink: string;
  handleSubmit: UseFormHandleSubmit<ClaimType>;
  register: UseFormRegister<ClaimType>;
  resetField: UseFormResetField<ClaimType>;
  setValue: UseFormSetValue<ClaimType>;
  watch: UseFormWatch<ClaimType>;
  isLoadingPolicies: boolean;
  isLoadingDraftFnol?: boolean;
  policies: Policies | undefined;
  signFiles: (files: File[]) => Promise<PresignedUploadLink[]>;
  rejectedAttachments?: FileRejection[];
}>({
  formState: {} as FormState<ClaimType>,
  getValues: (() => {}) as UseFormGetValues<ClaimType>,
  viewAllClaimsLink: '',
  handleSubmit: {} as UseFormHandleSubmit<ClaimType>,
  register: {} as UseFormRegister<ClaimType>,
  resetField: (() => {}) as UseFormResetField<ClaimType>,
  setValue: (() => {}) as UseFormSetValue<ClaimType>,
  watch: (() => {}) as UseFormWatch<ClaimType>,
  isLoadingPolicies: false,
  isLoadingDraftFnol: false,
  policies: undefined,
  signFiles: (() => {}) as unknown as (files: File[]) => Promise<[]>,
  rejectedAttachments: [],
});
