import { Fragment, useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Button, Divider, List } from '@material-ui/core';
import { Dialog } from '@nirvana/ui-kit';
import pMap from 'p-map';
import axios from 'axios';
import keyBy from 'lodash/keyBy';
import AttachmentIcon from '../../../assets/icons/attachment.svg?react';
import { Attachment } from '../contexts/newClaim';
import { FileListItem } from './FileListItem';

interface PresignedURL {
  url: string;
  key: string;
}

const MIN_FILE_SIZE = 4;
const MAX_FILE_SIZE = 20 * 1000 * 1000;

const UPLOAD_CONCURRENCY = 5;

export const acceptedFiles = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.ms-excel': ['.xlsx'],
  'image/*': ['.jpg', '.jpeg', '.png'],
  'video/*': ['.mov', '.mkv'],
};

const maxFileSizeInMB = (MAX_FILE_SIZE / 1_000_000).toFixed(0);

const fileRejectionCodes: { [key: string]: string } = {
  'file-invalid-type': `Invalid file type.\nValid file types are ${Object.values(
    acceptedFiles,
  )
    .flat()
    .join(', ')}`,
  'file-too-large': `File is too large. Max file size is ${maxFileSizeInMB}MB`,
};

type FileUploadDialogProps = {
  attachments: Attachment[];
  rejected?: FileRejection[];
  isOpen: boolean;
  onClose: () => void;
  // handlePresignedURLs is injected as a prop and not as an inner hook because this allows for
  // the possibility of reuse in the future (e.g. if we want to sign URLs for other entities).
  // Ideally this component should be shared between different parts of the app.
  handlePresignedURLs: (files: File[]) => Promise<PresignedURL[]>;
  setAttachments: (
    updater: (prevAttachments: Attachment[]) => Attachment[],
  ) => void;
};

export const FileUploadDialog = ({
  attachments,
  rejected,
  isOpen,
  onClose,
  handlePresignedURLs,
  setAttachments,
}: FileUploadDialogProps) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>(
    rejected || [],
  );

  const updateAttachment = useCallback(
    (fileName: string, values: Partial<Attachment>) => {
      setAttachments((prevAttachments) =>
        prevAttachments.map((attachment) =>
          attachment.file.name === fileName
            ? { ...attachment, ...values }
            : attachment,
        ),
      );
    },
    [setAttachments],
  );

  const handleDrop = useCallback(
    async (newFiles: File[]) => {
      const newFilesByName = keyBy(newFiles, 'name');

      // 1. Mark attachments as uploading
      const newAttachments: Attachment[] = newFiles.map((file) => ({
        file,
        uploadStatus: 'pending',
      }));
      setAttachments((prevAttachments) => [
        ...prevAttachments.filter(({ file }) => !newFilesByName[file.name]), // Edge case: submit file twice
        ...newAttachments,
      ]);

      // 2. Generate a presigned URL for each one of the newly added files
      let presignedURLs: PresignedURL[] = [];
      try {
        presignedURLs = await handlePresignedURLs(
          newAttachments.map(({ file }) => file),
        );

        // 3. Update the attachments with their newly generated presigned URLs
        newAttachments.forEach((attachment) => {
          const presignedURL = presignedURLs.find(({ key }) =>
            doesKeyMatchFile(key, attachment.file),
          );
          if (presignedURL) {
            updateAttachment(attachment.file.name, presignedURL);
          }
        });
      } catch (error) {
        attachments.forEach((attachment) => {
          if (newFilesByName[attachment.file.name]) {
            updateAttachment(attachment.file.name, { uploadStatus: 'failed' });
          }
        });
        return;
      }

      // 4. Upload each file using the presigned URL
      await pMap(
        presignedURLs,
        async ({ key, url }) => {
          const attachment = newAttachments.find(({ file }) =>
            doesKeyMatchFile(key, file),
          );
          if (!attachment) {
            return;
          }

          try {
            await uploadFileToS3(attachment.file, url);
            updateAttachment(attachment.file.name, { uploadStatus: 'success' });
          } catch (error) {
            updateAttachment(attachment.file.name, { uploadStatus: 'failed' });
          }
        },
        { concurrency: UPLOAD_CONCURRENCY },
      );
    },
    [attachments, handlePresignedURLs, setAttachments, updateAttachment],
  );

  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptedFiles,
    minSize: MIN_FILE_SIZE,
    maxSize: MAX_FILE_SIZE,
    noClick: true,
    noKeyboard: true,
    preventDropOnDocument: true,
    onDrop: handleDrop,
    onDropRejected: (fileRejections) => {
      setRejectedFiles([...rejectedFiles, ...fileRejections]);
    },
  });

  const removeAttachment = (attachment: Attachment) => {
    setAttachments((prev) =>
      prev.filter(({ file: { name } }) => name !== attachment.file.name),
    );
  };

  const closeDialog = () => {
    setRejectedFiles(rejected || []);
    onClose();
  };

  return (
    <Dialog
      actionDivider
      fullWidth
      onClose={closeDialog}
      open={isOpen}
      primaryAction={
        <Button color="primary" onClick={closeDialog} variant="contained">
          Done
        </Button>
      }
      title={<h2 className="text-xl font-medium">Upload Documents</h2>}
    >
      <div className="pb-6">
        <div
          {...getRootProps({
            className:
              'flex flex-col items-center relative py-12 md:py-16 px-5 bg-primary-extraLight rounded-lg border border-primary-light border-dashed',
          })}
        >
          <AttachmentIcon className="mb-4" />
          <h3 className="text-lg font-bold">Drag & drop file(s) here</h3>
          <p className="my-8 text-lg">or</p>
          <Button
            className="w-fit"
            component="label"
            tabIndex={-1}
            variant="outlined"
          >
            Browse
            <input
              {...getInputProps()}
              id="file-uploader"
              className="hidden"
              type="file"
              multiple
            />
          </Button>

          <div className="absolute text-xs text-center bottom-3 text-text-hint">
            <p>
              Valid file types: {Object.values(acceptedFiles).flat().join(', ')}
            </p>
            <p>Max size per file: {maxFileSizeInMB}MB</p>
          </div>
        </div>
        {attachments.length > 0 && (
          <div className="flex flex-col justify-start">
            <List
              subheader={
                <div className="mt-4">
                  <span className="col-auto pl-1 font-bold">
                    Uploaded files ({attachments.length})
                  </span>
                  <Divider className="mt-2 grow" />
                </div>
              }
            >
              {attachments.map((attachment: Attachment) => (
                <Fragment
                  key={`file-${attachment.file.name}-${attachment.file.type}`}
                >
                  <FileListItem
                    file={attachment.file}
                    onRemove={() => removeAttachment(attachment)}
                    isLoading={attachment.uploadStatus === 'pending'}
                  />
                  <Divider />
                </Fragment>
              ))}
            </List>
          </div>
        )}

        {rejectedFiles.length > 0 && (
          <div className="flex flex-col justify-start">
            <List
              subheader={
                <div className="mt-4">
                  <span className="col-auto pl-1 font-bold">
                    Unsupported attachments ({rejectedFiles.length})
                  </span>
                  <span className="col-auto pl-3 text-error-main">
                    These files will not be submitted
                  </span>
                  <Divider className="mt-2 grow" />
                </div>
              }
            >
              {rejectedFiles.map(({ file, errors }: FileRejection) => (
                <Fragment key={`file-${file}-${file.type}`}>
                  <div className="flex items-center justify-between">
                    <FileListItem file={file} />
                    <p className="w-full text-text-hint max-w-72">
                      {fileRejectionCodes[errors[0].code]}
                    </p>
                  </div>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </div>
        )}
      </div>
    </Dialog>
  );
};

function doesKeyMatchFile(key: string, file: File): boolean {
  return key.endsWith(`/${file.name}`);
}

async function uploadFileToS3(file: File, presignedURL: string) {
  const response = await axios.put(presignedURL, file, {
    headers: { 'Content-Type': file.type },
  });

  if (response.status !== 200) {
    throw new Error(`Failed to upload file: ${response.status}`);
  }
}
