import ImplerUploader from 'src/components/impler';
import { ImplerUploaderProps } from 'src/components/impler/impler';

const schema = [
  {
    key: 'vin',
    name: 'VIN',
    alternateKeys: [
      'Chassis',
      'VIN#',
      'VIN Number',
      '17 Digit VIN',
      '17 Digit VIN#',
      'Trailer#',
      'Serial',
      'Serial#',
      'Full VIN#',
    ],
    isRequired: true,
    isUnique: true,
    defaultValue: '<<null>>',
    type: 'Regex',
    regex:
      '^(?!(T|t)otal|TOTAL|(V|v)in|VIN|(V|v)alue|VALUE)([a-zA-Z0-9])[a-zA-Z0-9]*([a-zA-Z0-9])$',
  },
  {
    key: 'statedValue',
    name: 'Stated Value',
    alternateKeys: [
      'Amount',
      'Cost',
      'Renewal Value',
      'Stated Value',
      'Stated Amount',
      'Stated Amt',
      'Value',
      'Renewal',
      'Renewal Physical Damage',
      'Physical Damage Value',
      'Physical Damage Amount',
    ],
    isRequired: true,
    isUnique: false,
    defaultValue: '<<null>>',
    type: 'Regex',
    regex: '\\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{2})?(?![,.]?\\d)',
  },
];

interface ImplerProps
  extends Pick<
    ImplerUploaderProps,
    'onChange' | 'onMetaChange' | 'isDisabled'
  > {
  isApdCoverage: boolean;
  useFullNames?: boolean;
}

const Impler = ({ isApdCoverage, onChange, onMetaChange }: ImplerProps) => {
  return (
    <ImplerUploader
      templateId={import.meta.env.VITE_IMPLER_EQUIPMENTS_TEMPLATE_ID}
      extra={{ isApdCoverage }}
      title="Equipments List"
      onChange={onChange}
      onMetaChange={onMetaChange}
      isDisabled={false}
      schema={schema}
    />
  );
};

export default Impler;
