import { useImpler } from '@impler/react';
import { CircularProgress } from '@material-ui/core';
import { lightTheme } from '@nirvana/ui-kit';
import { useRef } from 'react';
import Button from 'src/components/button';

type FileUploadMetadata = {
  fileId: string;
  filename: string;
};

export interface ImplerUploaderProps {
  templateId: string;
  extra?: Record<string, any>;
  title: string;
  useFullNames?: boolean;
  onChange: (
    result: Record<string, any>[],
    metadata?: FileUploadMetadata,
  ) => void;
  onMetaChange?: (metadata?: FileUploadMetadata) => void;
  isDisabled?: boolean;
  schema: any;
}

const Impler = ({
  templateId,
  extra,
  title,
  schema,
  onChange,
  onMetaChange,
  isDisabled,
}: ImplerUploaderProps) => {
  const fileMetadataRef = useRef<FileUploadMetadata | undefined>(undefined);

  const { closeWidget, showWidget, isImplerInitiated } = useImpler({
    templateId,
    projectId: import.meta.env.VITE_IMPLER_PROJECT_ID,
    accessToken: import.meta.env.VITE_IMPLER_ACCESS_TOKEN,
    primaryColor: lightTheme.palette.primary.main,
    extra,
    title,
    texts: {
      COMMON: {
        UPLOAD_AGAIN: 'Restart',
      },
      FILE_DROP_AREA: {
        DROP_FILE: 'Drag and drop your file here',
        FILE_SELECTED: 'File uploaded',
      },
      PHASE1: {
        SELECT_SHEET_NAME: 'Select a sheet',
        SELECT_SHEET_NAME_PLACEHOLDER: 'Select sheet',
        SEE_MAPPING: 'Proceed',
      },
      PHASE2: {
        REVIEW_DATA: 'Proceed',
        IN_SCHEMA_TITLE: 'Required fields',
        IN_SHEET_TITLE: 'Select a field from the sheet',
      },
      PHASE3: {
        RE_REVIEW_DATA: 'Proceed',
        COMPLETE: 'Complete',
        ALL_RECORDS_VALID_TITLE: 'Validation successful!',
        ALL_RECORDS_VALID_DETAILS:
          'Importing {total} rows. Are you sure you want to proceed?',
      },
    },
    onUploadComplete: (data) => {
      const metadata = {
        fileId: data._id,
        filename: data.originalFileName,
      };
      fileMetadataRef.current = metadata;

      if (onMetaChange) {
        onMetaChange(metadata);
      }
    },
    onDataImported: (data) => {
      onChange(data, fileMetadataRef.current);
      closeWidget();
    },
  });

  return (
    <div>
      <Button
        variant="contained"
        type="button"
        disabled={!isImplerInitiated || isDisabled}
        onClick={() =>
          showWidget({
            schema,
            colorScheme: 'light',
          })
        }
        startIcon={
          !isImplerInitiated && (
            <CircularProgress className="text-white" size={18} />
          )
        }
      >
        {isImplerInitiated ? 'Proceed' : 'Initializing...'}
      </Button>
    </div>
  );
};

export default Impler;
