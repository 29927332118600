import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@material-ui/icons';
import { PatchEndorsementRequestMiscellaneousRequestBody } from '@nirvana/api/endorsementapp';
import { HorizontalStepper, Show } from '@nirvana/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formatISO, isValid, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-v7';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  closeEndorsement,
  fetchEndorsementDetails,
  submitEndorsement,
  updateEffectiveDate,
  updateMiscEndorsement,
} from '../../queries/endorsement';
import { formatEndorsementNumber } from '../../utils';
import EndorsementHeader from '../endorsement-header';
import { EndorsementType } from '../endorsement-summary/layout';
import CancelEndorsementModal from './cancelEnodrsementModal';
import EndorsementSidebar from './sidebar';
import { usePageInfo } from './utils';

const EndorsementLayout = () => {
  const {
    policyId = '',
    endorsementId = '',
    endorsementType = '',
  } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const pageInfo = usePageInfo();
  const { update: intercomUpdate } = useIntercom();
  const queryClient = useQueryClient();
  const [effectiveDate, setEffectiveDate] = useState<Date | undefined>();
  const [effectiveDateError, setEffectiveDateError] = useState<
    string | undefined
  >();
  const [cancelEndorsementModalOpen, setCancelEndorsementModalOpen] =
    useState(false);
  const isUpdateScreen = location?.pathname?.includes('update');
  const methods = useForm<PatchEndorsementRequestMiscellaneousRequestBody>();

  const { data } = useQuery(
    ['endorsement-details', policyId, endorsementId],
    () => fetchEndorsementDetails({ policyId, endorsementId }),
    {
      onSuccess: (data) => {
        if (data?.defaultEffectiveDate) {
          setEffectiveDate(parseISO(data.defaultEffectiveDate));
        }
      },
    },
  );

  const { mutate: mutateEffectiveDate } = useMutation(updateEffectiveDate);

  const { mutate, isLoading: isUpdatingEndorsement } = useMutation(
    submitEndorsement,
    {
      onSuccess: () => {
        navigate(`/policies/${policyId}/endorsement/${endorsementId}/success`);
      },
    },
  );

  const { mutate: miscMutate, isLoading: isUpdatingMisc } = useMutation(
    updateMiscEndorsement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'endorsement-details',
          policyId,
          endorsementId,
        ]);
        navigate(`/policies/${policyId}/endorsement/${endorsementId}`);
      },
      onError: (error: any) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
        });
      },
    },
  );

  const isSubmitting = isUpdatingEndorsement || isUpdatingMisc;

  const { mutate: cancelEndorsement } = useMutation(closeEndorsement, {
    onSuccess: () => {
      navigate(`/policies/${policyId}?tab=endorsements`, { replace: true });
    },
    onError: () => {
      enqueueSnackbar(
        'Unexpected error while canceling endorsement. Please try again later.',
        { variant: 'error' },
      );
    },
  });

  const hasChanges = useMemo(() => {
    return data?.changes?.some((change) => change.descriptions.length > 0);
  }, [data?.changes]);

  const activeStep = useMemo(() => {
    if (isUpdateScreen) {
      return 1;
    }

    if (hasChanges) {
      return 2;
    }

    return 0;
  }, [isUpdateScreen, hasChanges]);

  const steps = useMemo(() => {
    return [
      {
        key: 'selectChanges',
        label: <>Start</>,
      },
      {
        key: 'enterDetails',
        label: <>Make Changes</>,
      },
      {
        key: 'review',
        label: <>Review</>,
      },
    ];
  }, []);

  const handleEffectiveDateChange = (date?: Date) => {
    setEffectiveDate(date);
    setEffectiveDateError(undefined);

    if (date && isValid(date)) {
      mutateEffectiveDate({
        policyId,
        endorsementId,
        effectiveDate:
          formatISO(date, { representation: 'date' }) + 'T00:00:00Z',
      });
    }
  };

  const handleMiscSubmit = (
    payload: PatchEndorsementRequestMiscellaneousRequestBody,
  ) => {
    miscMutate({
      policyId,
      endorsementId,
      payload,
    });
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    if (endorsementType === EndorsementType.Miscellaneous) {
      methods.handleSubmit(handleMiscSubmit)();
      return;
    }

    if (isUpdateScreen) {
      navigate(`/policies/${policyId}/endorsement/${endorsementId}`);
      return;
    }

    if (!effectiveDate) {
      setEffectiveDateError('Effective date is required');
      return;
    }

    mutate({
      policyId,
      endorsementId,
    });
  };

  useEffect(() => {
    intercomUpdate({
      verticalPadding: 85,
    });

    return () => {
      intercomUpdate({
        verticalPadding: 0,
      });
    };
  }, [intercomUpdate]);

  const handleCancelEndorsement = () => {
    cancelEndorsement({ policyId, endorsementId });
  };

  return (
    <section className="relative flex justify-center h-full">
      <EndorsementHeader
        title={`Endorsement #${formatEndorsementNumber(
          data?.provisionalEndorsementNumber ?? '',
        )}`}
        subtitle={`${data?.insured?.name} | DOT: ${data?.insured?.DOTNumber}`}
        underwriterName={`${data?.underwriterInfo?.firstName} ${data?.underwriterInfo?.lastName}`}
        underwriterEmail={data?.underwriterInfo?.email}
        onBack={() =>
          navigate(`/policies/${policyId}?tab=policy`, { replace: true })
        }
      />
      <div className="flex w-full h-full mt-16 mr-[256px] 2xl:max-w-7xl">
        <Grid
          container
          direction="row"
          className="flex-1 h-full overflow-hidden"
          wrap="nowrap"
        >
          <Grid item p={5} flexGrow={1} className="space-y-8 overflow-y-auto">
            <Grid container alignItems="center">
              <Grid item flexGrow={1}>
                <Typography
                  variant="h4"
                  fontWeight="fontWeightBold"
                  color="textPrimary"
                  gutterBottom
                >
                  {pageInfo.title}
                </Typography>
                <FormHelperText>{pageInfo.description}</FormHelperText>
              </Grid>

              <Grid item flexGrow={1}>
                <HorizontalStepper
                  steps={steps}
                  activeStep={activeStep}
                  onChange={() => null}
                />
              </Grid>
            </Grid>
            <div>
              <FormProvider {...methods}>
                <Outlet />
              </FormProvider>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="w-[256px] bg-white px-6 py-10 border-primary-tint3 shadow fixed top-12 right-0 h-screen z-10">
        <EndorsementSidebar
          endorsementDetails={data}
          effectiveDate={effectiveDate}
          onEffectiveDateChange={(e) => handleEffectiveDateChange(e)}
          effectiveDateError={effectiveDateError}
        />
      </div>

      <div
        className="fixed bottom-0 z-10 flex items-center justify-between w-full px-8 py-2 bg-white"
        style={{ boxShadow: '0px -2px 4px rgba(128, 145, 196, 0.13)' }}
      >
        <Show when={!isUpdateScreen} fallback={<div />}>
          <Button
            type="button"
            onClick={() => setCancelEndorsementModalOpen(true)}
            size="small"
          >
            Cancel
          </Button>
        </Show>
        <div className="mr-8 space-x-2">
          <Show when={!isUpdateScreen}>
            <Button
              variant="outlined"
              type="button"
              startIcon={isUpdateScreen ? null : <ArrowBackIosRounded />}
              onClick={handlePrevious}
              size="small"
            >
              Previous
            </Button>
          </Show>
          <Button
            variant="contained"
            type="button"
            endIcon={isUpdateScreen ? null : <ArrowForwardIosRounded />}
            startIcon={
              isSubmitting ? (
                <CircularProgress className="text-white" size={18} />
              ) : null
            }
            onClick={handleSubmit}
            disabled={
              isUpdateScreen ? isSubmitting : isSubmitting || !hasChanges
            }
            size="small"
          >
            {isUpdateScreen ? 'Continue' : 'Submit'}
          </Button>
        </div>
      </div>

      <CancelEndorsementModal
        open={cancelEndorsementModalOpen}
        onClose={() => setCancelEndorsementModalOpen(false)}
        onConfirm={handleCancelEndorsement}
      />
    </section>
  );
};

export default EndorsementLayout;
