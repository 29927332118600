import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Driver } from '@nirvana/api/endorsementapp';
import {
  constants,
  DatePicker,
  InputNumeric,
  InputWithLabel,
  Show,
} from '@nirvana/ui-kit';
import { useQuery } from '@tanstack/react-query';
import {
  format,
  formatISO,
  isAfter,
  isBefore,
  isValid,
  parseISO,
  startOfDay,
  sub,
  subYears,
} from 'date-fns';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form-v7';
import { useParams } from 'react-router-dom';
import TrashIcon from 'src/assets/icons/trash.svg';
import stateLicenseRegex from 'src/constants/stateLicenseRegex';
import { fetchPolicyDetail } from 'src/features/policy/queries/policy';

type DriversFormProps = {
  title: string;
  driver?: Driver;
  onSubmit: (data: Driver) => void;
  onCancel: () => void;
  onRemove?: (driver: Driver) => void;
};

const DriversForm = ({
  title,
  driver,
  onRemove,
  onSubmit,
  onCancel,
}: DriversFormProps) => {
  const { policyId = '' } = useParams();
  const { data } = useQuery(['policy-detail', policyId], () =>
    fetchPolicyDetail(policyId),
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<Driver>();

  const licenseState = watch('licenseState');
  const effectiveDateTo = data?.effectiveDateTo;

  useEffect(() => {
    if (driver) {
      reset(driver);
    }
  }, [driver, reset]);

  return (
    <form
      className="p-6 space-y-3 overflow-hidden border rounded-lg border-text-disabled "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex items-center justify-between ">
        <p className="text-sm text-black">{title}</p>
        <div className="space-x-4">
          <Button size="small" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="small" type="submit" variant="contained">
            Save
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="First Name"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.firstName?.message}
            helperText={errors?.firstName?.message}
            {...register('firstName', {
              required: 'Please enter first name',
              pattern: {
                value: /^[a-zA-Z\s]*$/,
                message: 'Please enter valid first name',
              },
              maxLength: {
                value: 30,
                message: 'First name should not exceed 30 characters',
              },
            })}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="Last Name"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.lastName?.message}
            helperText={errors?.lastName?.message}
            {...register('lastName', {
              required: 'Please enter last name',
              pattern: {
                value: /^[a-zA-Z\s]*$/,
                message: 'Please enter valid last name',
              },
              maxLength: {
                value: 30,
                message: 'Last name should not exceed 30 characters',
              },
            })}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <InputWithLabel
            noMargin
            label="CDL"
            placeholder=""
            formControlProps={{
              fullWidth: true,
            }}
            error={!!errors?.licenseNumber?.message}
            helperText={errors?.licenseNumber?.message}
            {...register('licenseNumber', {
              required: 'Please enter DL number',

              // Validate DL number based on stateLicenseRegex and licenseState
              // Pass validation if licenseState is not selected
              pattern: {
                value: licenseState
                  ? new RegExp(stateLicenseRegex[licenseState]?.rule || '.*')
                  : /./,
                message: 'Please enter valid DL number',
              },
            })}
          />
        </FormControl>
        <FormControl>
          <FormHelperText className="text-primary-main">State</FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name={'licenseState'}
            rules={{ required: 'Please select state' }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  displayEmpty
                  variant="outlined"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors?.licenseState?.message}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">Select</Typography>
                  </MenuItem>
                  {constants.usStates.map((record) => (
                    <MenuItem value={record.code} key={record.code}>
                      {record.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>

        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">
            Date of Birth
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name="dateOfBirth"
            rules={{
              required: 'Please enter date of birth',
              validate: (value) => {
                const parsedDate = parseISO(value);
                if (!isValid(parsedDate)) {
                  return 'Invalid date format.';
                }

                const today = startOfDay(new Date());
                const minAllowedDate = subYears(today, 125); // Max age: 125 years
                const maxAllowedDate = subYears(today, 14); // Min age: 14 years

                if (isBefore(parsedDate, minAllowedDate)) {
                  return 'Must be within the last 125 years';
                }
                if (isAfter(parsedDate, maxAllowedDate)) {
                  return 'Must be at least 14 years old';
                }
                return true;
              },
            }}
            render={({ field }) => (
              <DatePicker
                value={
                  field.value && isValid(parseISO(field.value))
                    ? parseISO(field.value)
                    : null
                }
                InputProps={{
                  error: !!errors?.dateOfBirth?.message,
                }}
                OpenPickerButtonProps={{
                  size: 'small',
                  sx: { padding: 0, width: '18px' },
                }}
                minDate={subYears(new Date(), 125)} // Max age: 125 years
                maxDate={subYears(new Date(), 14)} // Min age: 14 years
                onChange={(date) => {
                  if (date && isValid(date)) {
                    field.onChange(formatISO(date, { representation: 'date' }));
                  }
                }}
              />
            )}
          />
          {errors?.dateOfBirth?.message && (
            <FormHelperText error>{errors.dateOfBirth.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl className="col-span-2">
          <FormHelperText className="text-primary-main">
            Date of Hire
          </FormHelperText>
          <Controller
            control={control}
            defaultValue=""
            name="dateOfHire"
            rules={{
              required: 'Please enter date of hire',
              validate: (value) => {
                const parsedDate = parseISO(value);
                if (!isValid(parsedDate)) {
                  return 'Invalid date format. Please enter a valid date (MM-DD-YYYY)';
                }

                // Skip validation if date of hire is already set in the driver data
                if (driver?.dateOfHire) {
                  return true;
                }

                const minDate = sub(startOfDay(new Date()), { days: 5 });
                const maxDate = effectiveDateTo
                  ? sub(parseISO(effectiveDateTo), { days: 1 })
                  : new Date();
                if (isBefore(parsedDate, minDate)) {
                  return `Date of hire cannot be earlier than ${format(
                    minDate,
                    'MM-dd-yyyy',
                  )}`;
                }
                if (isAfter(parsedDate, maxDate)) {
                  return `Date of hire cannot be later than ${format(
                    maxDate,
                    'MM-dd-yyyy',
                  )}`;
                }
                return true;
              },
            }}
            render={({ field }) => (
              <DatePicker
                value={
                  field.value && isValid(parseISO(field.value))
                    ? parseISO(field.value)
                    : null
                }
                InputProps={{
                  error: !!errors?.dateOfHire?.message,
                }}
                OpenPickerButtonProps={{
                  size: 'small',
                  sx: { padding: 0, width: '18px' },
                }}
                // Date of hire should be limited from current date -5 days to one day before expiry date
                maxDate={
                  effectiveDateTo
                    ? sub(parseISO(effectiveDateTo), { days: 1 })
                    : new Date()
                }
                minDate={sub(new Date(), { days: 5 })}
                onChange={(date) => {
                  if (date && isValid(date)) {
                    field.onChange(formatISO(date, { representation: 'date' }));
                  }
                }}
              />
            )}
          />
          {errors?.dateOfHire?.message && (
            <FormHelperText error>{errors.dateOfHire.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <FormHelperText className="text-primary-main">
            Years of CDL
          </FormHelperText>
          <Controller
            control={control}
            name={'yearsOfExperience'}
            rules={{
              required: 'Please enter years of CDL experience',
              validate: (value) => {
                if (value < 0) {
                  return 'Years of CDL cannot be negative';
                }
                if (value > 100) {
                  return 'Years of CDL cannot exceed 100';
                }
                return true;
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <InputNumeric
                  thousandSeparator={false}
                  value={value || ''}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10) || 0;
                    onChange(newValue);
                  }}
                  error={!!errors?.yearsOfExperience?.message}
                />
              );
            }}
          />
          {errors?.yearsOfExperience?.message && (
            <FormHelperText error>
              {errors.yearsOfExperience.message}
            </FormHelperText>
          )}
        </FormControl>
      </div>
      <Show when={!!onRemove}>
        <Button
          size="small"
          type="button"
          onClick={onRemove && driver ? () => onRemove(driver) : onCancel}
          startIcon={<img src={TrashIcon} />}
          className="-ml-4 leading-4"
        >
          Remove Driver
        </Button>
      </Show>
    </form>
  );
};

export default DriversForm;
