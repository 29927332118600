import ImplerUploader from 'src/components/impler';
import { ImplerUploaderProps } from 'src/components/impler/impler';

const getSchema = ({ useFullNames }: { useFullNames: boolean }) => [
  ...(useFullNames
    ? [
        {
          key: 'fullName',
          name: 'Full Name',
          alternateKeys: ['Name', 'Driver', 'Drivers Name', 'Full Name'],
          isRequired: true,
          isUnique: false,
          defaultValue: '',
          type: 'String',
        },
      ]
    : [
        {
          key: 'firstName',
          name: 'First Name',
          alternateKeys: ['First Name'],
          isRequired: true,
          isUnique: false,
          defaultValue: '',
          type: 'String',
        },
        {
          key: 'lastName',
          name: 'Last Name',
          alternateKeys: ['Name', 'Driver', 'Drivers Name'],
          isRequired: true,
          isUnique: false,
          defaultValue: '',
          type: 'String',
        },
      ]),
  {
    key: 'dlNumber',
    name: 'DL Number',
    alternateKeys: [
      'driver',
      'license',
      'License Number',
      'Driver License',
      'DL License',
      'CDL Number',
      'CDL License',
    ],
    isRequired: true,
    isUnique: true,
    defaultValue: '',
    type: 'String',
  },
  {
    key: 'usState',
    name: 'State',
    alternateKeys: [
      'state',
      'DL State',
      'CDL State',
      'License State',
      'State License',
      'Licenced Stated',
      'st',
      'St.',
    ],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
  {
    key: 'dateHired',
    name: 'Date Hired',
    alternateKeys: ['Hired Date', 'Hired', 'Hire Date', 'Date of Hire'],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
  {
    key: 'dateOfBirth',
    name: 'Date of Birth',
    alternateKeys: ['DOB', 'Birth Date', 'Birthdate', 'Date of Birth'],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
  {
    key: 'yearsOfExperience',
    name: 'Years of Experience',
    alternateKeys: ['Experience', 'Years', 'Experience Years', 'Year Licensed'],
    isRequired: true,
    isUnique: false,
    defaultValue: '',
  },
];

interface ImplerProps
  extends Pick<
    ImplerUploaderProps,
    'onChange' | 'onMetaChange' | 'isDisabled'
  > {
  usState?: string;
  useFullNames?: boolean;
}

const Impler = ({
  usState,
  useFullNames = false,
  onChange,
  onMetaChange,
  isDisabled,
}: ImplerProps) => {
  return (
    <ImplerUploader
      templateId={import.meta.env.VITE_IMPLER_DRIVERS_TEMPLATE_ID}
      extra={{
        applicationUSState: usState,
      }}
      title="Drivers List"
      onChange={onChange}
      onMetaChange={onMetaChange}
      isDisabled={isDisabled}
      schema={getSchema({ useFullNames })}
    />
  );
};

export default Impler;
