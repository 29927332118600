import {
  AppBar,
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Show } from '@nirvana/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { initSelector } from 'src/features/init/slices';
import { useAnalytics } from 'src/helpers/analytics';
import { Feature, useFeatureFlag } from 'src/helpers/featureFlags';
import { RoleGroupEnum } from 'src/types/graphql-types';
import { logout } from '../../features/auth/actions';
import Branding from '../branding';
import NewUserInviteModal from './newUserInviteModal';
import { useStyles } from './styles';

/**
 * The global header component that is displayed on authorized pages of the application.
 * @component
 *
 * @example
 * return <WhiteHeader />
 */
const WhiteHeader = ({ isPrivate }: { isPrivate: boolean }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const init = useSelector(initSelector);
  const getFeatureValue = useFeatureFlag();
  const { resetIdentity } = useAnalytics();
  const { user } = init;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [inviteModal, setInviteModal] = React.useState(false);

  const isInviteEnabled = getFeatureValue(Feature.AGENTS_INVITE, false);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate('/profile', { state: { backgroundLocation: location } });
    handleMenuClose();
  };

  const handleLogout = () => {
    dispatch(logout());
    handleMenuClose();

    resetIdentity();
  };

  const handleInviteModal = () => {
    setInviteModal(true);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const agencyAdminRole = React.useMemo(
    () =>
      user?.roles?.agencyRoles?.filter(
        ({ role }) => role === RoleGroupEnum.AgencyAdminRole,
      )[0],
    [user],
  );

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appbar}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Toolbar className={classes.toolbar}>
          <NavLink to="/applications/list" className={classes.brandLogo}>
            <Branding icon />
          </NavLink>
          <div className={classes.grow} />
          {isPrivate ? (
            <>
              <Show when={isInviteEnabled && agencyAdminRole}>
                <Button
                  variant="outlined"
                  className="mr-2"
                  onClick={handleInviteModal}
                >
                  Invite
                </Button>
              </Show>
              <List>
                <ListItem
                  button
                  onClick={handleProfileMenuOpen}
                  className={classes.listItem}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.avatar}>
                      {user?.name.substring(0, 1)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user?.name || ''}
                    secondary={null}
                    primaryTypographyProps={{
                      color: 'secondary',
                      variant: 'caption',
                    }}
                  />
                  <Box className={classes.listItemIcon}>
                    <ArrowDropDownIcon
                      color={isMenuOpen ? 'secondary' : undefined}
                    />
                  </Box>
                </ListItem>
              </List>
            </>
          ) : null}
        </Toolbar>
        <Show when={inviteModal}>
          <NewUserInviteModal
            open={inviteModal}
            onClose={() => setInviteModal(false)}
            agencyID={agencyAdminRole?.agency.id}
            inviterUserID={user?.id}
          />
        </Show>
      </AppBar>
      {renderMenu}
    </>
  );
};

export default WhiteHeader;
