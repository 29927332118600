import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AdditionalInformationForm,
  AppetiteForm,
  ApplicationApi,
  ApplicationDetail,
  ApplicationState,
  Configuration,
  CreatedApplicationResponse,
  DotPrefillResponse,
  DownloadFileLinkResponse,
  FileHandle,
  FileInfo,
  FileDestinationGroup,
  FileType,
  FlatfileMetadata,
  IndicationForm,
  IndicationOption,
  Producer,
  ApplicationTab,
  TelematicsConsentRequestEmailData,
} from '@nirvana/api/quoting';
import { RootState } from 'src/redux/reducers';
import { utils as UtilsHelper } from 'src/helpers';
import {
  deleteAvailableProducers,
  setActiveApplicationId,
  setActiveApplicationAttribute,
  setApplicationState,
  setAvailableProducers,
  setActiveApplication,
  setError,
  setFileUpload,
  resetFileUpload,
  removeFileUpload,
  setIftasFileUpload,
  resetIftasFileUpload,
  removeIftasFileUpload,
  setApplicationsList,
  appendApplicationsList,
  setPagination,
  setLoading,
  setStatus,
  setUpdateSuccess,
  FileUploadProgress,
  resetActiveApplication,
  setAdditionalFileUpload,
  removeAdditionalAgentFileUpload,
} from '../slices';
import { resetList } from '../slices/indicationOptions';

let uploadFiles: any = {};

export const fetchApplicationsList = createAsyncThunk(
  'applications/list',
  async (
    payload: {
      pageSize: number;
      tab: ApplicationTab;
      cursor?: string;
      q?: string;
    },
    { getState, dispatch }: any,
  ) => {
    const { auth } = getState();

    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const { data } = await apiService.applicationListGet(
      payload.pageSize,
      payload.cursor,
      payload.q,
      payload.tab,
    );

    // Dispatch action to store applications list in global app state
    dispatch(
      setPagination({ cursor: data.cursor, totalCount: data.totalCount }),
    );

    if (payload.cursor) {
      dispatch(appendApplicationsList(data.apps));
    } else {
      dispatch(setApplicationsList(data.apps));
    }

    return data;
  },
);

export const fetchApplicationState =
  (applicationID: string) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setStatus('loading'));

      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response: AxiosResponse<ApplicationState> =
        await apiService.applicationApplicationIDStateGet(applicationID);
      const { data } = response;

      dispatch(setStatus('succeeded'));

      dispatch(setApplicationState(data));
    } catch (ex) {
      dispatch(setStatus('failed'));
    }
  };

export const createApplication = createAsyncThunk(
  'application/create',
  async (
    payload: AppetiteForm,
    { dispatch, getState, rejectWithValue }: any,
  ) => {
    const { auth } = getState();

    try {
      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response: AxiosResponse<CreatedApplicationResponse> =
        await apiService.applicationPost(payload);
      const { data } = response;

      // Dispatch action to set active application in global app state
      dispatch(setActiveApplicationId({ applicationID: data.applicationID }));

      return data;
    } catch (ex: any) {
      return rejectWithValue(ex.response.data);
    }
  },
);

export interface IUpdateApplicationPayload {
  applicationID: string;
  payload: IndicationForm;
}

export const updateApplication = createAsyncThunk(
  'indicationOptions/updateIndication',
  async (data: IUpdateApplicationPayload, thunkAPI: any) => {
    const { applicationID, payload } = data;
    const { auth } = thunkAPI.getState();

    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    await apiService.applicationApplicationIDIndicationPut(
      applicationID,
      payload,
    );

    // Dispatch action to store applications list in global app state
    return thunkAPI.dispatch(
      setUpdateSuccess({ applicationID, indicationForm: payload }),
    );
  },
);

export const resetStatus = () => (dispatch: any) => {
  dispatch(setLoading(false));
  dispatch(setStatus('idle'));
};

export const fetchApplicationById =
  (applicationId: string) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setStatus('loading'));

      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response: AxiosResponse<ApplicationDetail> =
        await apiService.applicationApplicationIDGet(applicationId);
      const { data } = response;

      // Dispatch action to store active application in global app state
      return dispatch(setActiveApplication(data));
    } catch (ex: any) {
      dispatch(setStatus('failed'));
      return dispatch(setError({ message: ex.message }));
    }
  };

export const fetchAvailableProducers =
  () => async (dispatch: any, getState: () => RootState) => {
    try {
      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response: AxiosResponse<Producer[]> =
        await apiService.availableProducersGet();
      const { data } = response;

      // Dispatch action to store active application in global app state
      return dispatch(setAvailableProducers(data));
    } catch (ex: any) {
      return dispatch(setError({ message: ex.message }));
    }
  };

export const removeApplicationFileUpload =
  (progress: FileUploadProgress) => async (dispatch: any) => {
    delete uploadFiles[progress.handle];
    dispatch(removeFileUpload(progress));
  };

export const removeIftaApplicationFileUpload =
  (progress: FileUploadProgress) => async (dispatch: any) => {
    delete uploadFiles[progress.handle];
    dispatch(removeIftasFileUpload(progress));
  };

export const removeAdditionalAgentFileUploadAction =
  (progress: FileUploadProgress) => async (dispatch: any) => {
    delete uploadFiles[progress.handle];
    dispatch(removeAdditionalAgentFileUpload(progress));
  };

export const uploadApplicationFiles =
  (file: any, fileInfo: FileInfo, handle?: string) =>
  async (dispatch: any, getState: () => RootState) => {
    const fileHandle = handle || UtilsHelper.createGuid();

    let fileUploadHandler: any;
    switch (fileInfo.type) {
      case FileType.FileTypeIftaFile:
        fileUploadHandler = setIftasFileUpload;
        break;
      case FileType.FileTypeAgentAdditionalFiles:
        fileUploadHandler = setAdditionalFileUpload;
        break;
      default:
        fileUploadHandler = setFileUpload;
    }

    let removeFileHandler;
    switch (fileInfo.type) {
      case FileType.FileTypeIftaFile:
        removeFileHandler = removeIftasFileUpload;
        break;
      case FileType.FileTypeAgentAdditionalFiles:
        removeFileHandler = removeAdditionalAgentFileUpload;
        break;
      default:
        removeFileHandler = removeFileUpload;
    }

    try {
      dispatch(setStatus('loading'));
      dispatch(
        fileUploadHandler({
          name: file.name,
          handle: fileHandle,
          total: 100,
          loaded: 1,
          status: 'loading',
          updatedAt: Date.now(),
        }),
      );

      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      const apiService = new ApplicationApi(configOptions);
      uploadFiles[fileHandle] = {
        file,
        fileInfo,
        cancelToken: axios.CancelToken.source(),
      };

      const response: AxiosResponse<FileHandle> =
        await apiService.applicationFilePost(
          file,
          fileInfo.type,
          fileInfo.destinationGroup,
          {
            cancelToken: uploadFiles[fileHandle].cancelToken.token,
            onUploadProgress: (progressEvent: any) => {
              dispatch(
                fileUploadHandler({
                  name: file.name,
                  handle: fileHandle,
                  total: progressEvent.total,
                  loaded: progressEvent.loaded,
                  status: 'loading',
                }),
              );
            },
          },
        );
      const { data } = response;

      const fileData: FileUploadProgress = {
        ...data,
        name: file.name,
        status: 'succeeded',
      };

      dispatch(
        removeFileHandler({
          name: file.name,
          handle: fileHandle,
        }),
      );
      dispatch(fileUploadHandler(fileData));
      return dispatch(setStatus('succeeded'));
    } catch (ex: any) {
      dispatch(
        fileUploadHandler({
          name: file.name,
          handle: fileHandle,
          status: axios.isCancel(ex) ? 'cancelled' : 'failed',
        }),
      );
      dispatch(setStatus('failed'));
    }
  };

export const retryApplicationFilesUpload =
  (progress: FileUploadProgress) => async (dispatch: any) => {
    if (progress && progress.handle && uploadFiles[progress.handle]) {
      dispatch(
        uploadApplicationFiles(
          uploadFiles[progress.handle].file,
          uploadFiles[progress.handle].fileInfo,
          progress.handle,
        ),
      );
    }
  };

export const downloadApplicationFile =
  (handleId: string) => async (dispatch: any, getState: () => RootState) => {
    try {
      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response: AxiosResponse<DownloadFileLinkResponse> =
        await apiService.applicationFileLinkGet(handleId);
      const { data } = response;

      UtilsHelper.downloadFile(data.link);
    } catch (ex: any) {
      return dispatch(setError({ message: ex.message }));
    }
  };

export const cancelApplicationFilesUpload =
  (progress: FileUploadProgress) => async (dispatch: any) => {
    if (progress && progress.handle) {
      uploadFiles[progress.handle].cancelToken.cancel();
      dispatch(
        setFileUpload({
          ...progress,
          status: 'cancelled',
        }),
      );
    }
  };

export const resetApplicationFileUpload = () => async (dispatch: any) => {
  uploadFiles = {};
  dispatch(resetFileUpload());
};

export const resetIftaApplicationFileUpload = () => async (dispatch: any) => {
  uploadFiles = {};
  dispatch(resetIftasFileUpload());
};

export const resetAdditionalAgentFileUpload = () => async (dispatch: any) => {
  uploadFiles = {};
  dispatch(resetAdditionalAgentFileUpload());
};

type UploadFlatFilePayload = {
  flatfileMetadata: FlatfileMetadata;
  fileType: FileType;
  fileDestinationGroup: FileDestinationGroup;
};
export const uploadFlatFile = createAsyncThunk(
  'applications/uploadFlatFile',
  async (payload: UploadFlatFilePayload, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();

    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response: AxiosResponse<FileHandle> =
      await apiService.applicationFlatfilePost(payload);

    return response.data;
  },
);

export const updateAdditionalInformation =
  (applicationID: string, payload: AdditionalInformationForm) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));

      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      await apiService.applicationApplicationIDAdditionalInformationPut(
        applicationID,
        payload,
      );

      dispatch(setLoading(false));

      // Dispatch action to store applications list in global app state
      return dispatch(
        setUpdateSuccess({ applicationID, additionalInfoForm: payload }),
      );
    } catch (ex: any) {
      dispatch(setError({ message: ex.message }));
    }
  };

type FetchDOTInformationParams = {
  dotNumber: number;
};
export const fetchDOTInformation = createAsyncThunk(
  'applications/fetchDOTInformation',
  async ({ dotNumber }: FetchDOTInformationParams, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();

    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response: AxiosResponse<DotPrefillResponse> =
      await apiService.applicationPrefillDotNumberDotNumberGet(dotNumber);
    const { data } = response;

    return data;
  },
);

export const resetAvailableProducers = () => (dispatch: any) => {
  dispatch(deleteAvailableProducers());
};

export const removeActiveApplication = () => (dispatch: any) => {
  dispatch(resetActiveApplication());
};

export const submitIndication =
  (applicationId: string) =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(setStatus('loading'));

      const { auth } = getState();

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      await apiService.applicationApplicationIDIndicationSubmitPost(
        applicationId,
      );

      dispatch(setStatus('succeeded'));
    } catch (ex) {
      dispatch(setStatus('failed'));
    }
  };

export const selectIndicationOption =
  (applicationID: string, selectedIndication: IndicationOption) =>
  async (dispatch: any, getState: () => RootState) => {
    const { auth, application } = getState();
    const { activeApplication } = application;

    try {
      // Update application state
      dispatch(setUpdateSuccess({ applicationID, selectedIndication }));

      dispatch(setStatus('loading'));

      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      await apiService.applicationApplicationIDIndicationOptionsSelectPost(
        applicationID,
        { id: selectedIndication.id },
      );

      dispatch(fetchApplicationById(applicationID));

      dispatch(setStatus('succeeded'));
    } catch (ex) {
      dispatch(setStatus('failed'));

      // Revert application state
      dispatch(
        setUpdateSuccess({
          applicationID,
          selectedIndication: activeApplication?.selectedIndication,
        }),
      );
    }
  };

export const resetIndicationOptions = () => (dispatch: any) => {
  dispatch(resetList());
  dispatch(
    setActiveApplicationAttribute({
      key: 'selectedIndication',
      value: undefined,
    }),
  );
};

type DownloadQuotePDFParams = {
  applicationId: string;
};
export const downloadQuotePDFFile = createAsyncThunk(
  'application/quote-pdf-link',
  async (params: DownloadQuotePDFParams, { getState }: any) => {
    const { applicationId } = params;
    const { auth } = getState();

    const configOptions = new Configuration(auth.apiConfig);
    configOptions.isJsonMime = () => false;
    const apiService = new ApplicationApi(configOptions);

    const response = await apiService.applicationApplicationIDQuotePdfLinkGet(
      applicationId,
    );
    const { data } = response;

    return data.link;
  },
);

type RenewalApplicationParams = {
  applicationId: string;
};
export const createRenewalApplication = createAsyncThunk(
  'application/renew',
  async (
    params: RenewalApplicationParams,
    { getState, rejectWithValue }: any,
  ) => {
    const { applicationId } = params;
    const { auth } = getState();

    try {
      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      const response = await apiService.applicationApplicationIDRenewPost(
        applicationId,
      );
      const { data } = response;

      return data;
    } catch (ex: any) {
      return rejectWithValue(ex.response.data);
    }
  },
);

interface ISendTelematicsConsentRequestEmailPayload {
  applicationId: string;
  payload: TelematicsConsentRequestEmailData;
}

export const sendTelematicsConsentRequestEmail = createAsyncThunk(
  'application/send-consent-request-email',
  async (
    data: ISendTelematicsConsentRequestEmailPayload,
    { dispatch, getState, rejectWithValue }: any,
  ) => {
    const { auth } = getState();
    const { applicationId, payload } = data;

    try {
      const configOptions = new Configuration(auth.apiConfig);
      configOptions.isJsonMime = () => false;
      const apiService = new ApplicationApi(configOptions);

      await apiService.applicationApplicationIDTelematicsConsentRequestEmailPost(
        applicationId,
        payload,
      );

      // fetch latest application data
      await dispatch(fetchApplicationById(applicationId));
    } catch (ex: any) {
      return rejectWithValue(ex.response.data);
    }
  },
);
