import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  AdditionalInsuredChange,
  AdditionalInsuredChangeChangeTypeEnum,
} from '@nirvana/api/endorsementapp';
import { useFormContext } from 'react-hook-form-v7';
import AdditionalInsuredForm from './additional-insured-form';

const AdditionalInsured = ({
  hasBlanketWaiverOfSubrogation,
}: {
  hasBlanketWaiverOfSubrogation: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const additionalInsureds: AdditionalInsuredChange[] =
    watch('additionalInsureds') ?? [];

  return (
    <div className="flex flex-row items-start">
      <h6 className="font-bold basis-1/3">Additional Insured</h6>
      <div className="flex flex-col gap-6 basis-4/6">
        {additionalInsureds
          .filter(
            (record) =>
              record.changeType !==
              AdditionalInsuredChangeChangeTypeEnum.Unchanged,
          )
          .map((record, index) => {
            return (
              <AdditionalInsuredForm
                hasBlanketWaiverOfSubrogation={hasBlanketWaiverOfSubrogation}
                key={`ai-${record.additionalInsured.name}-${index}`}
                index={index}
                onRemove={() => {
                  const newAdditionalInsureds = additionalInsureds.filter(
                    (v) =>
                      v.additionalInsured.name !==
                      record.additionalInsured.name,
                  );

                  setValue('additionalInsureds', newAdditionalInsureds);
                }}
              />
            );
          })}
        <div>
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() =>
              setValue(
                'additionalInsureds',
                additionalInsureds.concat([
                  {
                    changeType: AdditionalInsuredChangeChangeTypeEnum.Added,
                    additionalInsured: {
                      name: '',
                      address: {
                        street: '',
                        city: '',
                        state: '',
                        zip: '',
                      },
                    },
                    hasWoS: false,
                  },
                ]),
              )
            }
          >
            Add Additional Insured
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInsured;
