import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import {
  Vehicle,
  VehicleClass,
  VehicleType,
  WeightClass,
} from '@nirvana/api/endorsementapp';
import {
  InputNumeric,
  InputWithLabel,
  InputZip,
  Show,
  Tooltip,
  constants,
} from '@nirvana/ui-kit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FocusEvent, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-v7';
import { useParams } from 'react-router-dom';
import IconExclamation from 'src/assets/icons/exclamation-warning.svg';
import IconInfo from 'src/assets/icons/info-warning.svg';
import PlusIcon from 'src/assets/icons/plus-primary.svg';
import TrashDarkIcon from 'src/assets/icons/trash-dark.svg';
import TrashIcon from 'src/assets/icons/trash.svg';
import AddressSearch from 'src/components/address-search';
import {
  decodeVehicleVINs,
  decodeZipInfo,
  fetchVehicleInfo,
} from 'src/features/admitted/queries/application';
import { vehicleConstants } from 'src/features/policy/constants/vehicle';
import { fetchPolicyDetail } from 'src/features/policy/queries/policy';
import { getWeightClassLabel, getWeightClassOptions } from './utils';
import VinConfirmationModal from './vinConfirmationModal';

type EquipmentsFormProps = {
  equipment?: Vehicle;
  existingVINs?: string[];
  onSubmit: (data: Vehicle) => void;
  onCancel: () => void;
  onRemove?: (driver: Vehicle) => void;
};

const EquipmentForm = ({
  equipment,
  onSubmit,
  onCancel,
  onRemove,
  existingVINs,
}: EquipmentsFormProps) => {
  const { policyId = '' } = useParams();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<Vehicle>();
  const vehiclesValues = watch();
  const [formWarnings, setFormWarnings] = useState<Record<string, ReactNode>>(
    {},
  );
  const [hasLimitWarning, setHasLimitWarning] = useState(false);
  const [isEditingLossPayee, setIsEditingLossPayee] = useState(false);
  const [showVINErrorSnackbar, setShowVINErrorSnackbar] = useState(false);
  const [showVinConfirmationModal, setShowVinConfirmationModal] =
    useState(false);
  const [invalidVins, setInvalidVins] = useState<string[]>([]);
  const [isVINDecodingDone, setIsVINDecodingDone] = useState(true);

  const { mutate: fetchVehicleInfoMutate } = useMutation(fetchVehicleInfo);
  const { mutate: decodeVINMutate, isLoading: isDecodeInProgress } =
    useMutation(decodeVehicleVINs);
  const { mutateAsync: decodeZipInfoMutate } = useMutation(decodeZipInfo);

  const { data } = useQuery(['policy-detail', policyId], () =>
    fetchPolicyDetail(policyId),
  );

  const hasApdCoverage = data?.coverages?.some(
    (coverage) => coverage.name === 'Auto Physical Damage',
  );

  useEffect(() => {
    if (equipment) {
      reset(equipment);

      if (equipment.lossPayee) {
        setIsEditingLossPayee(true);
      }
    }
  }, [equipment, reset]);

  const handleVINBlur = async (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const vinValue = e.target.value.trim();

    setIsVINDecodingDone(false); // Mark decoding as pending

    // If VIN is empty, clear the associated warnings and do nothing
    if (!vinValue) {
      setFormWarnings((prev) => ({ ...prev, vin: undefined }));
      setShowVINErrorSnackbar(false);
      setIsVINDecodingDone(true); // No decoding needed
      return;
    }

    const vinArray = [vinValue];

    // Call the VIN decoding API only if VIN is provided
    decodeVINMutate(vinArray, {
      onSuccess: (vinDecodingData) => {
        const vinResponse = vinDecodingData.find(
          (item) => item.vin === vinValue,
        );

        if (vinResponse?.hasDecodingError) {
          setFormWarnings((prev) => ({
            ...prev,
            vin: (
              <Box
                display="flex"
                alignItems="center"
                className="text-warning-main"
              >
                <img
                  src={IconExclamation}
                  alt="Warning"
                  className="w-3 h-3 mr-1"
                />
                <span className="text-xs">Invalid VIN</span>
              </Box>
            ),
          }));

          // Store only the latest invalid VIN
          setInvalidVins([vinValue]);
          setShowVINErrorSnackbar(true);
          setIsVINDecodingDone(false); // Mark decoding as incomplete
          return;
        }

        // If VIN is valid, clear any VIN-specific warning
        setFormWarnings((prev) => ({ ...prev, vin: undefined }));
        setInvalidVins([]); // Clear invalid VINs
        setShowVINErrorSnackbar(false); // Hide the snackbar
        setIsVINDecodingDone(true); // Mark decoding as successful

        // Fetch the vehicle info after successful VIN decoding
        fetchVehicleInfoMutate(
          { vins: vinValue },
          {
            onSuccess: ({ data }) => {
              if (data?.length) {
                const { type, modelYear, make, model, weightClass } = data[0];

                setValue('vehicleType', type as unknown as VehicleType);
                setValue('year', +modelYear);
                setValue('make', make);
                setValue('model', model);

                if (type && weightClass) {
                  setValue(
                    'weightClass',
                    weightClass?.replace('WeightClassV1_', '') as WeightClass,
                  );
                }
              }
            },
          },
        );
      },
    });
  };

  const handleVinValidationSkipProceed = () => {
    setShowVinConfirmationModal(false);
    setIsVINDecodingDone(true);
    onSubmit(vehiclesValues);
  };

  const handleSubmitWithValidation = (data: Vehicle) => {
    if (invalidVins.length > 0 || !isVINDecodingDone) {
      setShowVinConfirmationModal(true); // Open the modal if invalid VINs exist
      return; // Prevent form submission until the modal action is resolved
    }
    onSubmit(data); // Proceed with form submission if all validations pass
  };

  return (
    <>
      <form
        className="p-6 space-y-4 overflow-hidden border rounded-lg border-text-disabled "
        onSubmit={handleSubmit(handleSubmitWithValidation)}
      >
        <div className="flex items-center justify-between ">
          <p className="text-sm text-black">New equipment information</p>
          <div className="space-x-4">
            <Button size="small" type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button size="small" type="submit" variant="contained">
              Save
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-8 gap-4">
          <FormControl className="col-span-3">
            <Controller
              name="vin"
              control={control}
              rules={{
                required: 'Please enter VIN',
                validate: (value) => {
                  if (!value) {
                    return 'Please enter a VIN';
                  }
                  if (existingVINs?.includes(value)) {
                    return 'Duplicate VIN detected';
                  }
                  return true;
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputWithLabel
                  label="VIN"
                  placeholder="Please enter VIN"
                  value={value}
                  onChange={(e) =>
                    onChange(
                      e?.target?.value
                        ? e.target.value.trim().toUpperCase()
                        : '',
                    )
                  }
                  onBlur={(e) => {
                    onBlur();
                    handleVINBlur(e);
                  }}
                  error={!!errors?.vin?.message}
                  endAdornment={
                    isDecodeInProgress ? <CircularProgress size={20} /> : null
                  }
                  sx={
                    formWarnings?.vin
                      ? {
                          '& > fieldset': {
                            borderColor: 'warning.main',
                          },
                        }
                      : {}
                  }
                />
              )}
            />
            {formWarnings?.vin && (
              <FormHelperText error className="-mt-2">
                {formWarnings?.vin}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl className="col-span-1">
            <FormHelperText className="text-primary-main">Year</FormHelperText>
            <Controller
              control={control}
              name={'year'}
              rules={{
                required: 'Please enter year',
                validate: {
                  validYear: (value) => {
                    if (value && (value < 1900 || value > 2100)) {
                      return 'Please enter a valid year for the vehicle';
                    }
                  },
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputNumeric
                    placeholder="Year"
                    thousandSeparator={false}
                    value={value}
                    onChange={(e) =>
                      onChange(e.target.value ? +e.target.value : '')
                    }
                    error={!!errors?.year?.message}
                  />
                );
              }}
            />
          </FormControl>

          <FormControl className="col-span-2">
            <InputWithLabel
              noMargin
              label="Make"
              {...register('make', {
                required: 'Please enter make',
              })}
              error={!!errors.make?.message}
            />
          </FormControl>
          <FormControl className="col-span-2">
            <InputWithLabel
              noMargin
              label="Model"
              {...register('model', {
                required: 'Please enter model',
              })}
              error={!!errors.model?.message}
            />
          </FormControl>

          <FormControl className="col-span-2">
            <FormHelperText className="text-primary-main">Type</FormHelperText>
            <Controller
              control={control}
              defaultValue={undefined}
              name={'vehicleType'}
              rules={{ required: 'Please select vehicle type' }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    displayEmpty
                    variant="outlined"
                    value={value ?? ''}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setValue('vehicleClass', '' as VehicleClass);
                      setValue('weightClass', '' as WeightClass);
                    }}
                    error={!!errors.vehicleType?.message}
                  >
                    <MenuItem value="">
                      <Typography color="text.hint">Select</Typography>
                    </MenuItem>
                    {vehicleConstants?.vehicleTypes?.map((record) => (
                      <MenuItem value={record.value + ''} key={record.value}>
                        {record.label}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>

          <FormControl className="col-span-2">
            <FormHelperText className="text-primary-main">Class</FormHelperText>
            <Controller
              control={control}
              defaultValue={undefined}
              name={'vehicleClass'}
              rules={{
                required: 'Please select vehicle class',
              }}
              render={({ field: { onChange, value }, fieldState }) => {
                return (
                  <Select
                    displayEmpty
                    variant="outlined"
                    value={value ?? ''}
                    disabled={!vehiclesValues.vehicleType}
                    onChange={(e) => {
                      onChange(e.target.value);

                      // Check whether the selected weight class is valid for the selected vehicle type and class
                      // If not, reset the weight class value
                      const weightClassOptions = getWeightClassOptions(
                        vehiclesValues.vehicleType,
                        e.target.value as VehicleClass,
                      );
                      if (
                        !weightClassOptions.some(
                          (record) =>
                            record.value === vehiclesValues.weightClass,
                        )
                      ) {
                        setValue('weightClass', '' as WeightClass);
                      }
                    }}
                    error={!!fieldState.error}
                  >
                    <MenuItem value="">
                      <Typography color="text.hint">Select</Typography>
                    </MenuItem>
                    {(
                      (vehiclesValues?.vehicleType &&
                        vehicleConstants?.vehicleClassByType?.[
                          vehiclesValues.vehicleType
                        ]) ||
                      []
                    ).map((record: any) => (
                      <MenuItem value={record.value} key={record.value}>
                        {record.label}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>

          <FormControl className="col-span-2">
            <FormHelperText className="text-primary-main">
              GVW, lbs
            </FormHelperText>
            <Controller
              control={control}
              defaultValue={undefined}
              name={'weightClass'}
              rules={{ required: 'Please select weight class' }}
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => (
                <Select
                  displayEmpty
                  variant="outlined"
                  value={value ?? ''}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  inputRef={ref}
                  error={!!error}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <Typography color="text.hint">Select</Typography>;
                    }
                    return getWeightClassLabel(selected);
                  }}
                >
                  <MenuItem value="">
                    <Typography color="text.hint">Select</Typography>
                  </MenuItem>
                  {getWeightClassOptions(
                    vehiclesValues.vehicleType,
                    vehiclesValues.vehicleClass,
                  ).map((record) => (
                    <MenuItem value={record.value} key={record.value}>
                      <Box>
                        {record.label}
                        {!!record?.subtext && (
                          <FormHelperText>{record.subtext}</FormHelperText>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          {hasApdCoverage && (
            <FormControl className="col-span-2">
              <FormHelperText className="text-primary-main">
                Stated Value
              </FormHelperText>
              <Controller
                control={control}
                defaultValue={undefined}
                name={'statedValue'}
                rules={{
                  required: 'Please enter stated value',
                  validate: {
                    minValue: (value) => {
                      // Value should be greather than or equal to 5000 or 0
                      if (value && value < 5000) {
                        return 'Please enter a valid stated amount. Minimum value is $5,000';
                      }
                    },
                  },
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputNumeric
                      prefix="$"
                      placeholder="$"
                      value={value}
                      decimalScale={0}
                      onChange={(e) => onChange(+e.target.value)}
                      onBlur={() => {
                        if (!value) {
                          setFormWarnings((prev) => ({
                            ...prev,
                            statedValue:
                              'Vehicle will not be covered for Auto Physical Damage',
                          }));
                          setHasLimitWarning(false);
                        } else if (
                          (vehiclesValues.vehicleType === VehicleType.Truck ||
                            vehiclesValues.vehicleType ===
                              VehicleType.Tractor) &&
                          value &&
                          value > 250000
                        ) {
                          setFormWarnings((prev) => ({
                            ...prev,
                            statedValue: (
                              <Box display="flex" alignItems="center">
                                Higher than usual&nbsp;
                                <Tooltip
                                  title={
                                    <p>
                                      The stated value of trucks/tractors
                                      usually does not exceed 250k
                                    </p>
                                  }
                                >
                                  <img src={IconInfo} alt="more info" />
                                </Tooltip>
                              </Box>
                            ),
                          }));
                          setHasLimitWarning(true);
                        } else if (
                          (vehiclesValues.vehicleType === VehicleType.Pickup ||
                            vehiclesValues.vehicleType ===
                              VehicleType.Trailer) &&
                          value &&
                          value > 100000
                        ) {
                          setFormWarnings((prev) => ({
                            ...prev,
                            statedValue: (
                              <Box display="flex" alignItems="center">
                                Higher than usual&nbsp;
                                <Tooltip
                                  title={
                                    <p>
                                      The stated value of pickup/trailers
                                      usually does not exceed 100k
                                    </p>
                                  }
                                >
                                  <img src={IconInfo} alt="more info" />
                                </Tooltip>
                              </Box>
                            ),
                          }));
                          setHasLimitWarning(true);
                        } else {
                          setFormWarnings((prev) => ({
                            ...prev,
                            statedValue: undefined,
                          }));
                          setHasLimitWarning(false);
                        }
                      }}
                      error={!!errors?.statedValue?.message}
                      sx={
                        formWarnings?.statedValue
                          ? {
                              '& > fieldset': {
                                borderColor: 'warning.main',
                              },
                            }
                          : {}
                      }
                    />
                  );
                }}
              />
              {formWarnings?.statedValue && (
                <FormHelperText className="mt-1" sx={{ color: 'warning.main' }}>
                  {formWarnings?.statedValue}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </div>

        <Divider className="pt-2" />

        <Show
          when={isEditingLossPayee}
          fallback={
            <div className="flex items-center justify-between">
              <Button
                size="small"
                type="button"
                onClick={() => setIsEditingLossPayee(true)}
                startIcon={<img src={PlusIcon} />}
                className="-ml-4 leading-4"
              >
                Add Loss Payee
              </Button>
            </div>
          }
        >
          <div className="flex items-center justify-between ">
            <p className="text-sm text-black">Add loss payee</p>
          </div>

          <div className="grid grid-cols-8 gap-4">
            <FormControl className="col-span-4">
              <InputWithLabel
                noMargin
                label="Name"
                placeholder=""
                formControlProps={{
                  fullWidth: true,
                }}
                error={!!errors?.lossPayee?.name?.message}
                helperText={errors?.lossPayee?.name?.message}
                {...register('lossPayee.name')}
              />
            </FormControl>

            <FormControl className="col-span-4 pt-5">
              <Link
                className="flex text-sm font-normal leading-10 cursor-pointer text-text-primary"
                underline="none"
                onClick={() => {
                  setIsEditingLossPayee(false);
                  setValue('lossPayee', {
                    name: '',
                    address: {
                      street: '',
                      city: '',
                      state: '',
                      zip: '',
                    },
                  });
                }}
              >
                <img src={TrashDarkIcon} className="w-3 mr-2" />
                Remove Loss Payee
              </Link>
            </FormControl>

            <Controller
              control={control}
              defaultValue=""
              name="lossPayee.address.street"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl className="col-span-2">
                    <FormHelperText className="mt-0 text-primary-main">
                      Address
                    </FormHelperText>
                    <AddressSearch
                      value={value}
                      onValueChange={onChange}
                      onPlaceSelect={(placeDetails) => {
                        onChange(placeDetails?.street);
                        setValue('lossPayee.address.city', placeDetails?.city);
                        setValue(
                          'lossPayee.address.state',
                          placeDetails?.state,
                        );
                        setValue('lossPayee.address.zip', placeDetails?.zip);
                      }}
                    />

                    {!!(errors?.lossPayee as any)?.address?.street && (
                      <FormHelperText error>
                        {(errors?.lossPayee as any)?.address?.street?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />

            <FormControl className="col-span-2">
              <InputWithLabel
                noMargin
                label="City"
                placeholder=""
                formControlProps={{
                  fullWidth: true,
                }}
                error={!!errors?.lossPayee?.address?.city?.message}
                {...register('lossPayee.address.city')}
              />
            </FormControl>
            <FormControl className="col-span-2" size="small">
              <FormHelperText className="mt-0 text-primary-main">
                State
              </FormHelperText>
              <Controller
                control={control}
                defaultValue=""
                name="lossPayee.address.state"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      size="small"
                      displayEmpty
                      variant="outlined"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors?.lossPayee?.address?.state?.message}
                    >
                      <MenuItem value="">
                        <Typography color="text.hint">Select</Typography>
                      </MenuItem>
                      {constants.usStates.map((record) => (
                        <MenuItem value={record.name} key={record.code}>
                          {record.name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </FormControl>
            <FormControl className="col-span-2">
              <FormHelperText className="mt-0 text-primary-main">
                Zip
              </FormHelperText>
              <Controller
                control={control}
                defaultValue=""
                name="lossPayee.address.zip"
                rules={{
                  validate: {
                    isExtendedZip: (value) => {
                      if (!value) {
                        return true;
                      }

                      return (
                        value.length <= 5 ||
                        'Please remove the zip code extension'
                      );
                    },
                    isValidZip: async (value) => {
                      if (!value) {
                        return true;
                      }

                      try {
                        await decodeZipInfoMutate(value);

                        return true;
                      } catch (ex) {
                        return 'Please enter a valid ZIP code';
                      }
                    },
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <InputZip
                    placeholder="eg. 12345"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={!!errors?.lossPayee?.address?.zip?.message}
                  />
                )}
              />
            </FormControl>
          </div>
        </Show>

        <Show when={!!onRemove}>
          <Button
            size="small"
            type="button"
            onClick={
              onRemove && equipment ? () => onRemove(equipment) : onCancel
            }
            startIcon={<img src={TrashIcon} />}
            className="-ml-4 leading-4"
          >
            Remove Equipment
          </Button>
        </Show>

        {!!errors && (
          <FormHelperText error sx={{ pl: 2 }}>
            {(errors as any)?.[Object.keys(errors)[0]]?.message}
          </FormHelperText>
        )}

        <Snackbar
          open={hasLimitWarning}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ mb: 8 }}
        >
          <Alert
            severity="warning"
            icon={<img src={IconExclamation} />}
            variant="outlined"
            sx={{ bgcolor: 'warning.extraLight' }}
          >
            <Typography color="warning.main">
              The stated value of the equipment seems higher than usual.
              <br />
              Please check highlighted value before you proceed.
            </Typography>
          </Alert>
        </Snackbar>
        <Snackbar
          open={showVINErrorSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => setShowVINErrorSnackbar(false)}
          sx={{ mb: 8 }}
        >
          <Alert
            severity="error"
            icon={<img src={IconExclamation} />}
            variant="outlined"
            sx={{ bgcolor: 'error.extraLight' }}
          >
            <Typography color="error.main">
              Please correct the invalid VINs before proceeding.
            </Typography>
          </Alert>
        </Snackbar>
      </form>

      <VinConfirmationModal
        open={showVinConfirmationModal}
        onClose={() => setShowVinConfirmationModal(false)}
        invalidVins={invalidVins}
        onSkipProceed={handleVinValidationSkipProceed}
      />
    </>
  );
};

export default EquipmentForm;
