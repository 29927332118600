import { AgencyRole } from 'src/types/graphql-types';
import { startCase } from 'lodash';

const preferredOrder = [
  AgencyRole.Producer,
  AgencyRole.Marketer,
  AgencyRole.Leadership,
  AgencyRole.CustomerService,
  AgencyRole.Billing,
];

export const agencyRoleOptions = Object.entries(AgencyRole)
  .map(([key, value]) => ({
    value,
    label: startCase(key),
  }))
  .sort((a, b) => {
    const indexA = preferredOrder.indexOf(a.value as AgencyRole);
    const indexB = preferredOrder.indexOf(b.value as AgencyRole);

    // If both are in the preferred order, sort by their positions
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If one is in the preferred order, prioritize it
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    // If neither is in the preferred order, sort alphabetically
    return a.label.localeCompare(b.label);
  });
