import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  Vehicle,
  VehicleChangeChangeTypeEnum,
} from '@nirvana/api/endorsementapp';
import { Show, TableV8 } from '@nirvana/ui-kit';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchEndorsementRequestEquipments,
  updateEquipment,
} from 'src/features/policy/queries/endorsement';
import {
  getEquipmentFromEquipmentData,
  getEquipmentListData,
} from 'src/features/policy/utils';
import { getVehicleColumns, VehicleData } from '../../../constants/vehicle';
import EquipmentForm from './form';

const EquipmentsUpdate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [adding, setAdding] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<Vehicle>();
  const queryClient = useQueryClient();
  const equipmentEditForm = useRef<HTMLDivElement>(null);

  const { policyId = '', endorsementId = '' } = useParams();

  const { data = [] } = useQuery(
    ['endorsement-request-equipment', policyId, endorsementId],
    () => fetchEndorsementRequestEquipments(policyId, endorsementId),
    {
      select: (data) => data.equipments,
    },
  );

  const { mutate } = useMutation(updateEquipment, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'endorsement-request-equipment',
        policyId,
        endorsementId,
      ]);
      queryClient.invalidateQueries([
        'endorsement-details',
        policyId,
        endorsementId,
      ]);

      if (adding) {
        setAdding(false);
      }

      if (selectedEquipment) {
        setSelectedEquipment(undefined);
      }
    },
    onError: (error: any) => {
      enqueueSnackbar(error.response.data.message, {
        variant: 'error',
      });
    },
  });

  const equipmentList = useMemo(() => {
    return getEquipmentListData(data);
  }, [data]);

  const equipmentListNew = useMemo(() => {
    return equipmentList.filter(
      (driver) => driver.changeType === VehicleChangeChangeTypeEnum.Added,
    );
  }, [equipmentList]);

  const equipmentListExisting = useMemo(() => {
    return equipmentList.filter(
      (driver) => driver.changeType !== VehicleChangeChangeTypeEnum.Added,
    );
  }, [equipmentList]);

  const handleSubmit = (formData: Vehicle) => {
    let vehicles = equipmentList
      .filter(
        (record) => record.changeType !== VehicleChangeChangeTypeEnum.Deleted,
      )
      .map(getEquipmentFromEquipmentData);

    if (selectedEquipment) {
      vehicles = vehicles.map((record) => {
        if (record.vin === selectedEquipment.vin) {
          return formData;
        }

        return record;
      });
    } else {
      vehicles = [...vehicles, formData];
    }

    mutate({
      policyId,
      endorsementId,
      payload: { vehicles },
    });
  };

  const handleAddCancel = () => {
    setAdding(false);
  };

  const handleEditCancel = () => {
    setSelectedEquipment(undefined);
  };

  const handleRemove = (vehicle: VehicleData) => {
    const vehicles = equipmentList
      .filter(
        (record) => record.changeType !== VehicleChangeChangeTypeEnum.Deleted,
      )
      .map(getEquipmentFromEquipmentData)
      .filter((record) => {
        return record.vin !== vehicle.vin;
      });

    mutate({
      policyId,
      endorsementId,
      payload: { vehicles },
    });
  };

  const handleRemoveUndo = (vehicle: VehicleData) => {
    const vehicles = equipmentList
      .map((record) => {
        if (
          record.changeType === VehicleChangeChangeTypeEnum.Deleted &&
          record.vin === vehicle.vin
        ) {
          return {
            ...record,
            changeType: VehicleChangeChangeTypeEnum.Unchanged,
          };
        }

        return record;
      })
      .filter((record) => {
        return record.changeType !== VehicleChangeChangeTypeEnum.Deleted;
      })
      .map(getEquipmentFromEquipmentData);

    mutate({
      policyId,
      endorsementId,
      payload: { vehicles },
    });
  };

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="space-y-6">
      <div className="space-y-2 text-base font-bold">
        <p>Current Equipment</p>
        <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
          <TableV8
            columns={getVehicleColumns({
              onEdit: (selected) => {
                setSelectedEquipment(getEquipmentFromEquipmentData(selected));
                setTimeout(() => {
                  if (equipmentEditForm.current) {
                    window.scrollTo(0, equipmentEditForm.current.offsetTop);
                  }
                }, 300);
              },
              onRemove: (selected) => {
                handleRemove(selected);
              },
              onUndo: (selected) => {
                handleRemoveUndo(selected);
              },
            })}
            data={equipmentListExisting}
          />
        </div>
      </div>

      <Show when={!!selectedEquipment}>
        <EquipmentForm
          onSubmit={handleSubmit}
          onCancel={handleEditCancel}
          equipment={selectedEquipment}
          onRemove={handleRemove}
        />
      </Show>
      <div ref={equipmentEditForm} />

      <Show when={!!equipmentListNew.length}>
        <div className="space-y-2 text-base font-bold">
          <p>To be added</p>
          <div className="overflow-hidden bg-white border rounded-lg border-text-disabled">
            <TableV8
              columns={getVehicleColumns({
                onEdit: (selected) => {
                  setSelectedEquipment(getEquipmentFromEquipmentData(selected));
                },
                onRemove: (selected) => {
                  handleRemove(selected);
                },
                onUndo: (selected) => {
                  handleRemoveUndo(selected);
                },
              })}
              data={equipmentListNew}
            />
          </div>
        </div>
      </Show>

      <Show
        when={adding}
        fallback={
          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            onClick={() => setAdding(true)}
          >
            Add Equipment
          </Button>
        }
      >
        <div className="space-y-2 text-base font-bold">
          <p>To be added</p>
          <EquipmentForm
            onSubmit={handleSubmit}
            onCancel={handleAddCancel}
            existingVINs={equipmentList?.map((record) => record?.vin)}
          />
        </div>
      </Show>
    </div>
  );
};

export default EquipmentsUpdate;
