import { Button } from '@material-ui/core';
import AddIcon from 'src/assets/icons/plus-primary.svg';
import Coverages from './coverages';
import Drivers from './drivers';
import Equipments from './equipments';
import Miscellaneous from './miscellaneous';

export default function PolicyUpdate() {
  return (
    <>
      <Coverages />
      <Equipments />
      <Drivers />
      <Miscellaneous />
      <section>
        <div className="relative p-6 mb-5 overflow-hidden bg-white border rounded-lg border-text-disabled">
          <p className="mb-2 text-base font-bold">Other changes available</p>
          <p className="mb-6 text-sm text-primary max-w-[342px] leading-6">
            Please email uw@nirvanatech.com for any other changes
          </p>

          <Button
            color="primary"
            variant="outlined"
            startIcon={<img src={AddIcon} width={14} />}
            onClick={() => {}}
            size="small"
            href="mailto:uw@nirvanatech.com"
            type="link"
          >
            Create Email
          </Button>
          <div className="absolute top-0 bottom-0 right-0 w-[800px] h-[800px] bg-info-extraLight rounded-full origin-center translate-x-1/4 -translate-y-1/4" />
        </div>
      </section>
    </>
  );
}
