import {
  AddressChangeChangeTypeEnum,
  Driver,
  DriverChange,
  DriverChangeChangeTypeEnum,
  PolicyStatus,
  Vehicle,
  VehicleChange,
  VehicleChangeChangeTypeEnum,
} from '@nirvana/api/endorsementapp';
import { IChipColors } from '@nirvana/ui-kit/src/components/chip/chip';
import SuccessIcon from 'src/assets/icons/check-circle-sm.svg?react';
import { DriverData } from './constants/driver';
import { VehicleData } from './constants/vehicle';

export const getPolicyStatusChipInfo = (
  status?: PolicyStatus,
): {
  color: IChipColors;
  label: string;
  icon?: JSX.Element;
} => {
  switch (status) {
    case PolicyStatus.Active:
      return { color: 'success', label: status, icon: <SuccessIcon /> };

    default:
      return { color: 'default', label: status ?? '' };
  }
};

export const getDescriptionByCoverage = (coverage: string) => {
  switch (coverage) {
    case 'Auto Liability':
      return 'Covers damage to others’ property, vehicles, medical expenses, and more.';

    case 'Auto Physical Damage':
      return 'Covers your trucks and related equipment.';

    case 'Motor Truck Cargo':
      return 'Covers the cargo hauled in your trucks.';

    case 'General Liability':
      return 'Covers injuries or property damage related to your business (not related to your truck).';

    default:
      return '';
  }
};

export const getDriverListData = (data: DriverChange[]): DriverData[] => {
  return data.map((record) => {
    return {
      ...record.driver,
      changeType: record.changeType,
      oldValue: record.oldValue,
    };
  });
};

export const getDriverFromDriverData = (data: DriverData): Driver => {
  const driver = { ...data };
  delete driver.changeType;
  delete driver.oldValue;

  return driver;
};

const getChangeColor = {
  [DriverChangeChangeTypeEnum.Added]: 'success',
  [DriverChangeChangeTypeEnum.Updated]: 'warning',
  [DriverChangeChangeTypeEnum.Deleted]: 'error',
  [DriverChangeChangeTypeEnum.Unchanged]: 'info',
};

export const getChangeTypeMeta = (
  type?:
    | DriverChangeChangeTypeEnum
    | VehicleChangeChangeTypeEnum
    | AddressChangeChangeTypeEnum,
) => {
  if (!type) return {};

  return { color: getChangeColor[type] as IChipColors };
};

export const getEquipmentListData = (data: VehicleChange[]): VehicleData[] => {
  return data.map((record) => {
    return {
      ...record.vehicle,
      changeType: record.changeType,
      oldValue: record.oldValue,
    };
  });
};

export const getEquipmentFromEquipmentData = (data: VehicleData): Vehicle => {
  const vehicle = { ...data };
  delete vehicle.changeType;
  delete vehicle.oldValue;

  return vehicle;
};

export function formatEndorsementNumber(endorsementNumber: string): string {
  if (!endorsementNumber) return '';

  return endorsementNumber
    .split('.')
    .map((num, index) => (index === 0 ? num.padStart(3, '0') : num))
    .join('.');
}
